import React, { useState } from 'react';
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";

function ConfidentialityPolicy() {

    const [key, setKey] = useState('home');
    return (
        <div className="page-project-single">
            <div className="container">
                <div className="project-entry">
                    <Tabs defaultTab="vertical-tab-one" vertical className="vertical-tabs">
                        <TabList>
                            <Tab tabFor="vertical-tab-one"><h1 className='policytabs'>Confidentiality Policy</h1></Tab>
                            <Tab tabFor="vertical-tab-two"><h1 className='policytabs'>Data Protection Policy</h1></Tab>
                            <Tab tabFor="vertical-tab-three"><h1 className='policytabs'>Social Media Policy</h1></Tab>
                            <Tab tabFor="vertical-tab-four"><h1 className='policytabs'>Refund Policy</h1></Tab>
                            <Tab tabFor="vertical-tab-five"><h1 className='policytabs'>Equality and Diversity Policy</h1></Tab>
                            <Tab tabFor="vertical-tab-six"><h1 className='policytabs'>Sage Academy Bullying & Harassment Policy</h1></Tab>
                            <Tab tabFor="vertical-tab-seven"><h1 className='policytabs'>Health & Safety Policy</h1></Tab>
                            <Tab tabFor="vertical-tab-eight"><h1 className='policytabs'>Appeals Policy</h1></Tab>
                            <Tab tabFor="vertical-tab-nine"><h1 className='policytabs'>Safeguarding of Children and Adults at Risk Policy</h1></Tab>
                            <Tab tabFor="vertical-tab-ten"><h1 className='policytabs'>Mobile Phone Policy</h1></Tab>
                            <Tab tabFor="vertical-tab-eleven"><h1 className='policytabs'>Mission Statement</h1></Tab>
                            <Tab tabFor="vertical-tab-twelve"><h1 className='policytabs'>Student Handbook</h1></Tab>
                        </TabList>
                        <TabPanel tabId="vertical-tab-one">
                            <div className='policy'>
                                <h1 className='policyh1 mt-0'>Confidentiality Policy</h1>
                                <h1>Purpose of Document</h1>
                                <p>The purpose of this document is to ensure all staff members at the College are aware of their legal duty to maintain conﬁdentiality, to inform them of the processes in place to protect personal information, and to provide guidance on disclosure obligations.</p>

                                <h2>Introduction</h2>
                                <p>Everyone working for the college or elsewhere within the business is under a legal duty to keep student’s personal information conﬁdential. Students who believe their conﬁdence has been breached may make a complaint to the college, and they could take legal action or report it to the ICO. In the case of a registered dental professional tutor, the student could also make a complaint to the General Dental Council, which, in worst-case scenarios, may end in erasure from the GDC register.</p>
                                <p>This policy is concerned with protecting personal student information, although its content would apply equally to staff personal information or business-sensitive information.</p>
                                <p>Personal information is data in any form (paper, electronic, tape, verbal, etc.) from which a living individual could be identiﬁed including:</p>
                                <ul>
                                    <li>Name, age, address, and personal circumstances, as well as sensitive personal information like race, health, sexuality, etc.</li>
                                    <li>Information regarding appointments Information regarding medical histories</li>
                                    <li>Information regarding ﬁnances, including any bad debts.</li>
                                </ul>
                                <p>Although the Data Protection Act 2018 is only relevant to the personal information of living individuals, this code also covers information about students. This policy applies to all staff, including permanent, temporary, and locum staff members.</p>

                                <h2>Conﬁdentiality</h2>
                                <p>Under the Data Protection Act 2018 and UK GDPR, dental colleges must keep personal data about their student safe and secure and to ensure it is only accessed by persons who need to see it for the purposes of providing safe, effective education.</p>
                                <p>Registered dental professionals and tutors have an ethical and legal duty to keep all student information conﬁdential.</p>

                                <h2>Caldicott Principles</h2>
                                <p>The Caldicott Principles are the guidelines for ensuring people's information is kept conﬁdential and used or shared appropriately within a healthcare setting.</p>
                                <p>All NHS organizations must have an appointed Caldicott Guardian. This won't apply to most dental colleges, although there should be someone within the college who is responsible for ensuring student information is kept conﬁdential and shared appropriately when required.</p>
                                <p>The Caldicott Principles</p>
                                <ul>
                                    <li>Principle 1: Justify the purpose for using the conﬁdential information.</li>
                                    <li>Principle 2: Use conﬁdential information only when it is necessary</li>
                                    <li>Principle 3: Use the minimum necessary conﬁdential information</li>
                                    <li>Principle 4: Access to conﬁdential information should be on a strict need-to-know basis</li>
                                    <li>Principle 5: Everyone with access to conﬁdential information should be aware of their responsibilities</li>
                                    <li>Principle 6: Comply with the law</li>
                                    <li>Principle 7: The duty to share information for individual education is as important as the duty to protect students' conﬁdentiality</li>
                                </ul>

                                <h2>Disclosing Student Information</h2>
                                <p>Personal information relating to a student should only be shared with third parties where the student has given consent or in exceptional circumstances (GDC Standards 4.3).</p>
                                <p>Examples of where information may be shared without consent include:</p>
                                <ul>
                                    <li>In safeguarding concerns where it is not possible to gain consent and a referral needs to be made to the local authority or the police.</li>
                                    <li>Where information has been ordered by a court or by a coroner, where a court order has requested information, only the minimum amount of information should be disclosed.</li>
                                </ul>
                                <p>Before disclosing information to third parties where consent has not been obtained, you are advised to contact your indemnity provider.</p>

                                <h2>The Importance of Conﬁdentiality</h2>
                                <p>The relationship between the tutor and student is based on the understanding that any information revealed by the student to the tutor will not be divulged without the student’s consent. Students have the right to privacy and they must give to organization full information on their state of educational history to ensure that the education is carried out effectively. The intensely personal nature of health information means that many student would be reluctant to provide the collage with information if they felt the information would be passed on.</p>
                                <p>Education must be taken to ensure that conﬁdentiality is never breached, even to the most minor degree, in the use of social media or websites (GDC Standards 4.2.3).</p>

                                <h2>Recognize Your Obligations</h2>
                                <p>A duty of conﬁdence arises out of the common law duty of conﬁdence, employment contracts, and for registered dental professionals, it is part of your professional obligations. Breaches of conﬁdence and inappropriate use of records or computer systems are serious matters which could result in disciplinary proceedings, dismissal, and possibly legal prosecution.</p>
                                <p>So, make sure you do not:</p>
                                <ul>
                                    <li>Put personal information at risk of unauthorized access.</li>
                                    <li>Knowingly misuse any personal information or allow others to do so.</li>
                                    <li>Access records or information that you have no legitimate reason to look at. This includes records and information about your family, friends, neighbors, and acquaintances.</li>
                                </ul>

                                <h2>GDC standards guidance</h2>
                                <p>Dental Educational professionals have an ethical and legal duty to ensure they are familiar with and comply with the GDC’s standards and guidance. All team members must also follow this guidance and ensure that they maintain student conﬁdentiality. Copies of this publication in full are available as PDF downloads from the GDC’s website at www.gdc-uk.org</p>
                                <p>4.2	    Protect the conﬁdentiality of students’ information and only use it for the purpose for which it was given.</p>
                                <p>4.2.1	Conﬁdentiality is central to the relationship and trust between you and your student. You must keep student information conﬁdential. This applies to all the information about students that you have  learned in your professional role including personal details, student educational history, what education they are having, and how much it costs.</p>
                                <p>4.2.3    You must not post any information or comments about students on social networking or blogging sites. If you use professional social media to discuss anonymized cases for the purpose of    discussing best education you must be careful that the student or students cannot be identiﬁed.</p>

                                <p>The latest approved version of this document supersedes all other versions, upon receipt of the latest approved version all other versions should be destroyed unless speciﬁcally stated that previous version(s) are to remain extant. If in any doubt, please contact the document Author.</p>
                                <p>Approved By: Dr. Jasmin Thoria, Dr. Kuldipsinh Gohil, Sukhdeep Kaeda Date Published: 28/06/2024</p>
                            </div>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-two">
                            <div className='policy'>
                                <h1 className='policyh1 mt-0'>Data Protection Policy</h1>
                                <p>This Policy is specifically for Dental colleges in England and Wales and is a mutual agreement between the college entity and the staff (comprising of both employees and non-employed staff members)</p>
                                <p>Please be aware that this Policy and Annexures are generic and do not negate the necessity for specific advice and a thorough review of the document to precisely reﬂect your circumstances. This is strongly recommended:</p>
                                <p>to use the GDPR Risk assessment link to assess the suitability of your Policy and carry out a Data Protection Impact Assessment (DPIA) as set out in clause 19 before implementing this Policy.</p>
                                <h2>Contents</h2>
                                <ol className='listol'>
                                    <li>Interpretation</li>
                                    <li>Introduction</li>
                                    <li>Scope of Policy and when to seek advice on data protection compliance</li>
                                    <li>Personal data protection principles</li>
                                    <li>Lawfulness, fairness, and transparency</li>
                                    <li>Consent</li>
                                    <li>Transparency (notifying Data Subjects)</li>
                                    <li>Purpose limitation</li>
                                    <li>Data minimisation</li>
                                    <li>Accuracy</li>
                                    <li>Storage limitation</li>
                                    <li>Security integrity and confidentiality</li>
                                    <li>Reporting a Personal Data Breach</li>
                                    <li>Transfer limitation</li>
                                    <li>Data Subject's rights and requests</li>
                                    <li>Accountability</li>
                                    <li>Record keeping</li>
                                    <li>Training and audit</li>
                                    <li>Privacy by Design and Data Protection Impact Assessment (DPIA)</li>
                                    <li>Processing (including profiling) and Automated Decision-Making</li>
                                    <li>Direct marketing</li>
                                    <li>Sharing Personal Data</li>
                                    <li>Caldicott Principles</li>
                                    <li>Changes to this Data Protection Policy</li>
                                </ol>

                                <ol className='ollicountdot'>
                                    <li className='h2li'>
                                        <span>Interpretation</span>
                                        <ol>
                                            <li className='h3li'>
                                                Definitions:
                                                <div className='pContanier'>
                                                    <p><b>Automated Decision-Making (ADM):</b> when a decision is made based solely on Automated Processing (including profiling), which produces legal effects or significantly affects an individual. The UK GDPR prohibits Automated Decision-Making (unless certain conditions are met) but not Automated Processing.</p>
                                                    <p><b>Automated Processing:</b> any form of automated processing of Personal Data consisting of the use of Personal Data to evaluate certain personal aspects relating to an individual, in particular, to analyze or predict aspects concerning that individual's performance at work, economic situation, health, personal preferences, interests, reliability, behavior, location, or movements. Profiling is an example of automated processing, as are many uses of artificial intelligence (AI), which involves the processing of personal data.</p>
                                                    <p><b>Caldicott Principles:</b> 8 Principles used to ensure people’s information is kept confidential and used appropriately.</p>
                                                    <p><b>Company name:</b> [Sage Academy].</p>
                                                    <p><b>Company Personnel:</b> all employees, associates, and, without limitation, other self-employed staff, workers, contractors, agency workers, consultants, directors, members, and others.</p>
                                                    <p><b>Consent:</b> agreement which must be freely given, specific, informed, and be an unambiguous indication of the Data Subject's wishes by which they, by a statement or by a clear positive action, signify agreement to the Processing of Personal Data relating to them.</p>
                                                    <p><b>Controller:</b> the person or organization that determines when, why, and how to process Personal Data. It is responsible for establishing practices and policies in line with the UK GDPR. We are the Controller of all Personal Data relating to our Company Personnel and Personal Data used in our business for our own commercial purposes.</p>
                                                    <p><b>Criminal Convictions Data:</b> personal data relating to criminal convictions and offenses, including personal data relating to criminal allegations and proceedings.</p>
                                                    <p><b>Data Subject:</b> a living, identified, or identifiable individual about whom we hold Personal Data. Data Subjects may be nationals or residents of any country and may have legal rights regarding their Personal Data.</p>
                                                    <p><b>Data Privacy Impact Assessment (DPIA):</b> tools and assessments used to identify and reduce risks of a data processing activity. A DPIA can be carried out as part of Privacy by Design and should be conducted for all major system or business setups, as well as on adopting this Policy and any change programs involving the Processing of Personal Data.</p>
                                                    <p><b>Data Protection Officer (DPO):</b> either of the following:</p>
                                                    <ul>
                                                        <li>The person required to be appointed in specific circumstances under the UK GDPR; or</li>
                                                        <li>where wholly a college and a mandatory DPO have not been appointed, a Data Protection Lead is recommended to take responsibility for data protection compliance.</li>
                                                    </ul>
                                                    <p><b>Explicit Consent:</b> consent that requires a very clear and specific statement (that is, not just action).</p>
                                                    <p><b>UK GDPR:</b> the retained EU law version of the General Data Protection Regulation ((EU) 2016/679) as defined in the Data Protection Act 2018. Personal Data is subject to the legal safeguards specified in the UK GDPR.</p>
                                                    <p><b>Personal Data:</b> any information identifying a Data Subject or information relating to a Data Subject that we can identify (directly or indirectly) from that data alone or in combination with other identifiers we possess or can reasonably access. Personal Data includes Special Categories of Personal Data and Pseudonymized Personal Data but excludes anonymous data or data that has had the identity of an individual permanently removed. Personal data can be factual (for example, a name, email address, location, or date of birth) or an opinion about that person's actions or behavior. Personal Data specifically includes but is not limited to, medical records.</p>
                                                    <p><b>Personal Data Breach:</b> any act or omission that compromises the security, confidentiality, integrity, or availability of Personal Data or the physical, technical, administrative, or organizational safeguards that we or our third-party service providers put in place to protect it. The loss or unauthorized access, disclosure, or acquisition of Personal Data is a Personal Data Breach.</p>
                                                    <p><b>Privacy by Design:</b> implementing appropriate technical and organizational measures in an effective manner to ensure compliance with the UK GDPR.</p>
                                                    <p><b>Privacy Guidelines:</b> The company privacy and UK GDPR-related guidelines and policies provided to assist in interpreting and implementing this Data Protection Policy and Related Policies are available on the DCME compliance portal or on display at the practice.</p>
                                                    <p><b>Privacy Notices (also referred to as Fair Processing Notices) or Privacy Policies:</b> separate notices setting out information that may be provided to Data Subjects when the Company collects information about them. These notices may take the form of:</p>
                                                    <ul>
                                                        <li>general privacy statements applicable to a specific group of individuals (for example, employee privacy notices or the website privacy policy) or</li>
                                                        <li>stand-alone, one-time privacy statements covering Processing related to a specific purpose.</li>
                                                    </ul>
                                                    <p><b>Processing or Process:</b> any activity that involves the use of Personal Data. It includes obtaining, recording, or holding the data or carrying out any operation or set of operations on the data, including organizing, amending, retrieving, using, disclosing, erasing, or destroying it. Processing also includes transmitting or transferring Personal Data to third parties.</p>
                                                    <p><b>Pseudonymization or Pseudonymized:</b> replacing information that directly or indirectly identifies an individual with one or more artificial identifiers or pseudonyms so that the person to whom the data relates cannot be identified without the use of additional information which is meant to be kept separately and secure.</p>
                                                    <p><b>Related Policies:</b> the Company's policies, operating procedures, or processes related to this Data Protection Policy and designed to protect Personal Data and available from your line manager or the DPO.</p>
                                                    <p><b>Special Categories of Personal Data:</b> information revealing racial or ethnic origin, political opinions, religious or similar beliefs, trade union membership, physical or mental health conditions, sexual life, sexual orientation, biometric or genetic data. [The Company will treat the following types of data as if they are Special Categories of Personal Data.</p>

                                                </div>
                                            </li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Introduction</span>
                                        <ol className='pContanier'>
                                            <li>This Data Protection Policy sets out how (<strong>"we", "our", "us", and "the Company"</strong>) handle the Personal Data of our students, their parents or carers, customers, prospective students, suppliers, employees, workers, business contacts and other third parties.</li>
                                            <li>This Data Protection Policy applies to all Personal Data we Process regardless of the media on which that data is stored or whether it relates to past or present employees, workers, customers, clients or supplier contacts, shareholders, website users, or any other Data Subject.</li>
                                            <li>This Data Protection Policy applies to all Company Personnel (<strong>"you", "your"</strong>). You must read, understand, and comply with this Data Protection Policy when Processing Personal Data on our behalf and attend training on its requirements. Data protection is the responsibility of everyone within the Company, and this Data Protection Policy sets out what we expect from you when handling Personal Data to enable the Company to comply with applicable law. Your compliance with this Data Protection Policy is mandatory. Related Policies and Privacy Guidelines are available to help you interpret and act in accordance with this Data Protection Policy. You must also comply with all those Related Policies and Privacy Guidelines. Any breach of this Data Protection Policy may result in disciplinary action.</li>
                                            <li>Where you have a specific responsibility in connection with Processing, such as capturing Consent, reporting a Personal Data Breach or conducting a DPIA as referenced in this Data Protection Policy or otherwise, then you must comply with the Related Policies and Privacy Guidelines.</li>
                                            <li>This Data Protection Policy (together with Related Policies and Privacy Guidelines) is an internal document and cannot be shared with third parties, clients, or regulators without prior authorization from the DPO unless legally required to do so.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Scope of Policy and when to seek advice on data protection compliance.</span>
                                        <ol className='pContanier'>
                                            <li>We recognize that the correct and lawful education of Personal Data will maintain trust and confidence in the organization and will provide for successful business operations. Protecting the confidentiality and integrity of Personal Data is a critical responsibility we take seriously. The Company is exposed to potential fines of up to £17.5 million or 4% of total worldwide annual turnover, whichever is higher and depending on the breach, for failure to comply with the UK GDPR.</li>
                                            <li>All line managers and other responsible parties are responsible for ensuring all Company Personnel comply with this Data Protection Policy and must implement appropriate practices, processes, controls, and training to ensure compliance.</li>
                                            <li>The DPO oversees this Data Protection Policy and, as applicable, develops Related Policies and Privacy Guidelines. That post is held by Dr Jasmin Thoria, and they can be reached at [INSERT TELEPHONE NUMBER] and [EMAIL ADDRESS].</li>
                                            <li>Please contact the DPO with any questions about the operation of this Data Protection Policy or the UK GDPR or if you have any concerns that this Data Protection Policy is not being or has not been followed. In particular, you must always contact the DPO in the following circumstances:
                                                <ul>
                                                    <li>If you are unsure of the lawful basis on which you are relying to process Personal Data (including the legitimate interests used by the Company) (see paragraph 5.1).</li>
                                                    <li>If you need to rely on Consent or need to capture Explicit Consent (see paragraph 6).</li>
                                                    <li>If you need to draft Privacy Notices (see paragraph 7).</li>
                                                    <li>If you are unsure about the retention period for the Personal Data being Processed (see paragraph 11).</li>
                                                    <li>If you are unsure what security or other measures you need to implement to protect Personal Data (see paragraph 12.1).</li>
                                                    <li>If there has been a Personal Data Breach (paragraph 13).</li>
                                                    <li>If you are unsure on what basis to transfer Personal Data outside the UK (see paragraph 14).</li>
                                                    <li>If you need any assistance dealing with any rights invoked by a Data Subject (see paragraph 15).
                                                        <ul style={{ marginTop: '10px' }}>
                                                            <li>Whenever you are engaging in a significant new or change in Processing activity that is likely to require a DPIA (see paragraph 19) or plan to use Personal Data for purposes other than for which it was collected (see paragraph 8).</li>
                                                            <li>If you plan to undertake any activities involving Automated Processing, including profiling or Automated Decision-Making (see paragraph 20).</li>
                                                        </ul>
                                                    </li>
                                                    <li>If you need help complying with applicable law when carrying out direct marketing activities (see paragraph 21).
                                                        <ul>
                                                            <li>If you need help with any contracts or other areas in relation to sharing Personal Data with third parties (including our vendors) (see paragraph 22).</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Personal data protection principles</span>
                                        <ol className='pContanier'>
                                            <li>We adhere to the principles relating to the Processing of Personal Data set out in the UK GDPR, which require Personal Data to be:
                                                <ul>
                                                    <li>Processed lawfully, fairly and in a transparent manner (lawfulness, fairness, and transparency).</li>
                                                    <li>Collected only for specified, explicit and legitimate purposes (purpose limitation).</li>
                                                    <li>Adequate, relevant, and limited to what is necessary in relation to the purposes for which it is Processed (data minimization).</li>
                                                    <li>Accurate and, where necessary, kept up to date (accuracy).</li>
                                                    <li>Not kept in a form that permits the identification of Data Subjects for longer than is necessary for the purposes for which the data is Processed (storage limitation).</li>
                                                    <li>Processed in a manner that ensures its security using appropriate technical and organizational measures to protect against unauthorized or unlawful Processing and accidental loss, destruction, or damage (security, integrity, and confidentiality).</li>
                                                    <li>Not transferred to another country without appropriate safeguards in place (transfer limitation).</li>
                                                    <li>Made available to Data Subjects and allow Data Subjects to exercise certain rights in relation to their Personal Data (data subject's rights and requests).</li>
                                                </ul>
                                            </li>
                                            <li>
                                                We are responsible for and must be able to demonstrate compliance with the data protection principles listed above (accountability).
                                            </li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Lawfulness, fairness, and transparency</span>
                                        <ol className='pContanier'>
                                            <li>
                                                Personal data must be processed lawfully, fairly, and transparently in relation to the Data Subject.
                                            </li>
                                            <li>
                                                You may only collect, Process, and share Personal Data fairly, lawfully, and for specified purposes. The UK GDPR restricts our actions regarding Personal Data to specified lawful purposes. These restrictions are not intended to prevent processing but to ensure that we process personal data fairly and without adversely affecting the data subject.
                                            </li>
                                            <li>The UK GDPR allows Processing for specific purposes, some of which are set out below:
                                                <ul>
                                                    <li>The Data Subject has given their Consent.</li>
                                                    <li>The processing is necessary for the performance of a contract with the Data Subject.</li>
                                                    <li>To meet our legal compliance obligations.</li>
                                                    <li>To protect the Data Subject's vital interests.</li>
                                                    <li>To pursue our legitimate interests (or those of a third party) for purposes where they are not overridden because the Processing prejudices the interests or fundamental rights and freedoms of Data Subjects. The purposes for which we process Personal Data for legitimate interests need to be set out in applicable Privacy Notices.</li>
                                                    <li>[OTHER UK GDPR PROCESSING GROUNDS].</li>
                                                </ul>
                                            </li>
                                            <li>You must identify and document the legal ground being relied on for each Processing activity [in accordance with the Company's guidelines on the Lawful Basis for Processing Personal Data, available or from the DPO.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Consent</span>
                                        <ol className='pContanier'>
                                            <li>A Controller must only process Personal Data on one or more of the lawful bases set out in the UK GDPR, which include Consent.</li>
                                            <li>A Data Subject consents to the Processing of their Personal Data if they clearly indicate agreement to the Processing. Consent requires affirmative action, so silence, pre-ticked boxes, or inactivity will not be sufficient to indicate consent. If consent is given in a document that deals with other matters, then consent must be kept separate from other matters.</li>
                                            <li>A Data Subject must be easily able to withdraw Consent to Processing at any time, and withdrawal must be promptly honored. Consent may need to be refreshed if you intend to process personal data for a different and incompatible purpose that was not disclosed when the Data Subject first consented.</li>
                                            <li>When processing Special Category Data or Criminal Convictions Data, we will usually rely on a legal basis for processing other than Explicit Consent or Consent if possible. Where Explicit Consent is relied on, you must issue a Privacy Notice to the Data Subject to capture Explicit Consent.</li>
                                            <li>You will need to evidence Consent and keep records of all Consent in accordance with Related Policies and Privacy Guidelines so that the Company can demonstrate compliance with Consent requirements.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Transparency (notifying Data Subjects)</span>
                                        <ol className='pContanier'>
                                            <li>The UK GDPR requires a Controller to provide detailed, specific information to a Data Subject depending on whether the information was collected directly from the Data Subject or from elsewhere. The information must be provided through an appropriate Privacy Notice, which must be concise, transparent, intelligible, easily accessible, and in clear and plain language so that a Data Subject can easily understand them.</li>
                                            <li>Whenever we collect Personal Data directly from a Data Subject, including for HR or employment purposes, we must provide the Data Subject with all the information required by the UK GDPR, including the identity of the Controller and DPO and how and why we will use, Process, disclose, protect and retain that Personal Data through a Privacy Notice which must be presented when the Data Subject first provides the Personal Data.</li>
                                            <li>When Personal Data is collected indirectly (for example, from a third party or publicly available source), we must provide the Data Subject with all the information required by the UK GDPR as soon as possible after collecting or receiving the data. We must also check that the third party collected the Personal Data in accordance with the UK GDPR and on a basis that considers our proposed Processing of that Personal Data.</li>
                                            <li>If you are collecting Personal Data from a Data Subject, directly or indirectly, then you must provide the Data Subject with a Privacy Notice in accordance with our Related Policies and Privacy Guidelines.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Purpose limitation</span>
                                        <ol className='pContanier'>
                                            <li>Personal Data must be collected only for specified, explicit and legitimate purposes. It must not be further Processed in any manner incompatible with those purposes.</li>
                                            <li>You cannot use Personal Data for new, different, or incompatible purposes from that disclosed when it was first obtained unless you have informed the Data Subject of the new purposes and they have Consented where necessary.</li>
                                            <li>If you want to use Personal Data for a new or different purpose from that for which it was obtained, you must first contact the DPO for advice on how to do this in compliance with both the law and this Data Protection Policy.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Data minimization</span>
                                        <ol className='pContanier'>
                                            <li>Personal Data must be adequate, relevant, and limited to what is necessary in relation to the purposes for which it is processed.</li>
                                            <li>You may only process personal data when performing your job duties that require it. You cannot Process Personal Data for any reason unrelated to your job duties.</li>
                                            <li>You may only collect Personal Data that you require for your job duties; do not collect excessive data. Ensure any Personal Data collected is adequate and relevant for the intended purposes.</li>
                                            <li>You must ensure that when Personal Data is no longer needed for specified purposes, it is deleted or anonymized in accordance with the Company's data retention guidelines.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Accuracy</span>
                                        <ol className='pContanier'>
                                            <li>Personal Data must be accurate and, where necessary, kept up to date. It must be corrected or deleted without delay when inaccurate.</li>
                                            <li>You must ensure that the Personal Data we use and hold is accurate, complete, kept up to date, and relevant to the purpose for which we collected it. You must check the accuracy of any Personal Data at the point of collection and regular intervals afterward. You must take all reasonable steps to destroy or amend inaccurate or out-of-date Personal Data.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Storage limitation</span>
                                        <ol className='pContanier'>
                                            <li>Personal Data must not be kept in an identifiable form for longer than is necessary for the purposes for which the data is processed.</li>
                                            <li>The Company will maintain retention policies and procedures to ensure Personal Data is deleted after an appropriate time unless a law requires that data be kept for a minimum time. [You must comply with the Company's Data Retention Policy.]</li>
                                            <li>You must not keep Personal Data in a form that permits the identification of the Data Subject for longer than needed for the legitimate business purpose or purposes for which we originally collected it, including for the purpose of satisfying any legal, accounting, or reporting requirements.</li>
                                            <li>You will take all reasonable steps to destroy or erase from our systems all Personal Data that we no longer require in accordance with all the Company's applicable records retention schedules and policies. This includes requiring third parties to delete that data where relevant.</li>
                                            <li>You will provide Data Subjects with information about the period for which data is stored and how that period is determined in any applicable Privacy Notice.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Security integrity and confidentiality</span>
                                        <ol className='pContanier'>
                                            <li>Personal Data must be secured by appropriate technical and organizational measures against unauthorized or unlawful Processing and against accidental loss, destruction, or damage.</li>
                                            <li>We will develop, implement, and maintain safeguards appropriate to our size, scope and business, our available resources, the amount of Personal Data that we own or maintain on behalf of others, and identified risks (including use of encryption and Pseudonymization where applicable). We will regularly evaluate and test the effectiveness of those safeguards to ensure the security of our Processing of Personal Data. You are responsible for protecting the Personal Data we hold. You must implement reasonable and appropriate security measures against unlawful or unauthorized Processing of Personal Data and against the accidental loss of, or damage to, Personal Data. You must exercise particular care in protecting Special Categories of Personal Data and Criminal Convictions Data from loss and unauthorized access, use or disclosure.</li>
                                            <li>You must follow all procedures and technologies we put in place to maintain the security of all Personal Data from the point of collection to the point of destruction. You may only transfer Personal Data to third-party service providers who agree to comply with the required policies and procedures and who agree to put adequate measures in place, as requested.</li>
                                            <li>You must maintain data security by protecting the confidentiality, integrity and availability of the Personal Data, defined as follows:
                                                <ul>
                                                    <li>Confidentiality: only people who have a need to know and are authorized to use the Personal Data can access it;</li>
                                                    <li>Integrity: Personal Data is accurate and suitable for the purpose for which it is processed;</li>
                                                    <li>Availability: Authorized users are able to access their personal data when they need it for authorized purposes.</li>
                                                </ul>
                                            </li>
                                            <li>You must comply with and not attempt to circumvent the administrative, physical and technical safeguards we implement and maintain in accordance with the UK GDPR and relevant standards to protect Personal Data.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Reporting a Personal Data Breach</span>
                                        <ol className='pContanier'>
                                            <li>The UK GDPR requires Controllers to notify any Personal Data Breach to the Information Commissioner and, in certain instances, the Data Subject.</li>
                                            <li>We have put in place procedures to deal with any suspected Personal Data Breach and will notify the Data Subject or any applicable regulator where we are legally required to do so.</li>
                                            <li>If you know or suspect that a Personal Data Breach has occurred, do not attempt to investigate the matter yourself. Immediately contact the person or team designated as the key point of contact for Personal Data Breaches or the DPO) You should preserve all evidence relating to the potential Personal Data Breach.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Transfer limitation</span>
                                        <ol className='pContanier'>
                                            <li>The UK GDPR restricts data transfers to countries outside the UK to ensure that the level of data protection afforded to individuals by the UK GDPR is not undermined. You transfer Personal Data originating in one country across borders when you transmit, send, view or access that data in or to a different country.</li>
                                            <li>You must comply with the Company's guidelines on cross-border data transfers.</li>
                                            <li>You may only transfer Personal Data outside the UK if one of the following conditions applies:
                                                <ul>
                                                    <li>The UK has issued regulations confirming that the country to which we transfer the Personal Data ensures an adequate level of protection for the Data Subject's rights and freedoms;</li>
                                                    <li>Appropriate safeguards are in place, such as binding corporate rules, standard contractual clauses approved for use in the UK, an approved code of conduct, or a certification mechanism, a copy of which can be obtained from the DPO;</li>
                                                    <li>The Data Subject has provided Explicit Consent to the proposed transfer after being informed of any potential risks;</li>
                                                    <li>The transfer is necessary for one of the other reasons set out in the UK GDPR, including:
                                                        <ul>
                                                            <li>The performance of a contract between us and the Data Subject;</li>
                                                            <li>Reasons of public interest;</li>
                                                            <li>To establish, exercise, or defend legal claims;</li>
                                                            <li>To protect the vital interests of the Data Subject where the Data Subject is physically or legally incapable of giving Consent; and</li>
                                                            <li>In some limited cases, for our legitimate interest.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Data Subject's rights and requests</span>
                                        <ol className='pContanier'>
                                            <li>A Data Subject has rights regarding how we handle their Personal Data. These include rights to:
                                                <ul>
                                                    <li>Withdraw Consent to Processing at any time;</li>
                                                    <li>Receive certain information about the Controller's Processing activities;</li>
                                                    <li>Request access to their Personal Data that we hold (including receiving a copy of their Personal Data);</li>
                                                    <li>Prevent our use of their Personal Data for direct marketing purposes;</li>
                                                    <li>Ask us to erase Personal Data if it is no longer necessary in relation to the purposes for which it was collected or Processed, to rectify inaccurate data, or to complete incomplete data;</li>
                                                    <li>Restrict Processing in specific circumstances;</li>
                                                    <li>Object to Processing which has been justified on the basis of our legitimate interests or in the public interest;</li>
                                                    <li>Request a copy of an agreement under which Personal Data is transferred outside of the UK;</li>
                                                    <li>Object to decisions based solely on Automated Processing, including profiling (ADM);</li>
                                                    <li>Prevent Processing that is likely to cause damage or distress to the Data Subject or anyone else;</li>
                                                    <li>Be notified of a Personal Data Breach which is likely to result in a high risk to their rights and freedoms;</li>
                                                    <li>Make a complaint to the supervisory authority;</li>
                                                    <li>In limited circumstances, receive or ask for their Personal Data to be transferred to a third party in a structured, commonly used and machine-readable format; and</li>
                                                    <li>[ANY OTHER RIGHTS YOU MAY BE REQUIRED TO PROVIDE UNDER COMPANY POLICIES].</li>
                                                </ul>
                                            </li>
                                            <li>You must verify the identity of an individual requesting data under any of the rights listed above (do not allow third-parties to persuade you into disclosing Personal Data without proper authorization).</li>
                                            <li>You must immediately forward any Data Subject request you receive to the DPO, and they must comply with the Company's Response procedures for data subject requests.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Accountability</span>
                                        <ol className='pContanier'>
                                            <li>The Controller must implement appropriate technical and organizational measures effectively to ensure compliance with data protection principles. The Controller is responsible for and must be able to demonstrate compliance with the data protection principles.</li>
                                            <li>The Company must have adequate resources and controls in place to ensure and document UK GDPR compliance, including:
                                                <ul>
                                                    <li>Appointing a suitably qualified DPO (where necessary) and an executive accountable for data privacy;</li>
                                                    <li>Implementing Privacy by Design when Processing Personal Data and completing DPIAs where Processing presents a high risk to the rights and freedoms of Data Subjects;</li>
                                                    <li>Integrating data protection into internal documents, including this Data Protection Policy, Related Policies, Privacy Guidelines or Privacy Notices;</li>
                                                    <li>Regularly training Company Personnel on the UK GDPR, this Data Protection Policy, Related Policies and Privacy Guidelines, and data protection matters including, for example, a Data Subject's rights, Consent, legal basis, DPIA, and Personal Data Breaches. The Company must maintain a record of training attendance by Company Personnel; and</li>
                                                    <li>Regularly test the privacy measures implemented and conduct periodic reviews and audits to assess compliance, including using the results of testing to demonstrate compliance improvement efforts.</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Record keeping</span>
                                        <ol className='pContanier'>
                                            <li>The UK GDPR requires us to keep full and accurate records of all our data Processing activities.</li>
                                            <li>You must keep and maintain accurate corporate records reflecting our Processing, including records of Data Subjects' Consents and procedures for obtaining Consents in accordance with the Company's record-keeping guidelines.</li>
                                            <li>These records should include, at a minimum:
                                                <ul>
                                                    <li>the name and contact details of the Controller and the DPO, and</li>
                                                    <li>clear descriptions of:
                                                        <ul>
                                                            <li>the Personal Data types;</li>
                                                            <li>the Data Subject types;</li>
                                                            <li>the Processing activities;</li>
                                                            <li>the Processing purposes;</li>
                                                            <li>the third-party recipients of the Personal Data;</li>
                                                            <li>the Personal Data storage locations;</li>
                                                            <li>the Personal Data transfers;</li>
                                                            <li>the Personal Data's retention period; and</li>
                                                            <li>the security measures in place.</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>To create the records, data maps should be created, which should include the details set out above together with appropriate data flows.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Training and audit</span>
                                        <ol className='pContanier'>
                                            <li>We are required to ensure all Company Personnel have undergone adequate training to enable them to comply with data privacy laws. We must also regularly test our systems and processes to assess compliance.</li>
                                            <li>You must undergo all mandatory data privacy-related training and ensure your team undergo similar training [per the Company's mandatory training guidelines].</li>
                                            <li>You must regularly review all the systems and processes under your control to ensure they comply with this Data Protection Policy and check that adequate governance controls and resources are in place to ensure proper use and protection of Personal Data.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Privacy by Design and Data Protection Impact Assessment (DPIA)</span>
                                        <ol className='pContanier'>
                                            <li>We are required to implement Privacy by Design measures when Processing Personal Data by implementing appropriate technical and organizational measures (like Pseudonymization) effectively to ensure compliance with data privacy principles.</li>
                                            <li>You must assess what Privacy by Design measures can be implemented on all programs, systems, or processes that Process Personal Data by taking into account the following:
                                                <ul>
                                                    <li>The state of the art.</li>
                                                    <li>The cost of implementation.</li>
                                                    <li>The nature, scope, context, and purposes of Processing.</li>
                                                    <li>The risks of varying likelihood and severity for rights and freedoms of the Data Subject posed by the Processing.</li>
                                                </ul>
                                            </li>
                                            <li>The Controller must also regularly and not less than annually conduct a DPIA with respect to high-risk Processing.</li>
                                            <li>You should conduct a DPIA (and discuss your findings with the DPO) annually and when implementing a college set-up and before implementing this Policy and any major system or business change programs involving the Processing of Personal Data, including:
                                                <ul>
                                                    <li>Use of new technologies (programs, systems, or processes, including the use of AI) or changing technologies (programs, systems or processes).</li>
                                                    <li>Automated Processing, including profiling and ADM.</li>
                                                    <li>Large-scale Processing of Special Categories of Personal Data or Criminal Convictions Data.</li>
                                                    <li>Large-scale, systematic monitoring of a publicly accessible area.</li>
                                                </ul>
                                            </li>
                                            <li>A DPIA must include:
                                                <ul>
                                                    <li>A description of the Processing, its purposes and the Controller's legitimate interests if appropriate.</li>
                                                    <li>An assessment of the necessity and proportionality of the Processing in relation to its purpose.</li>
                                                    <li>An assessment of the risk to individuals.</li>
                                                    <li>The risk mitigation measures are in place, and compliance is demonstrated.</li>
                                                </ul>
                                            </li>
                                            <li>You must comply with the Company's guidelines on DPIA and Privacy by Design.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Processing (including profiling) and Automated Decision-Making</span>
                                        <ol className='pContanier'>
                                            <span><b>[This section can be removed if no automated decision-making is used]</b></span>
                                            <li className='mt-2'>Generally, ADM is prohibited when a decision has a legal or similar significant effect on an individual unless:
                                                <ul>
                                                    <li>a Data Subject has Explicitly Consented.</li>
                                                    <li>the Processing is authorized by law, or</li>
                                                    <li>the Processing is necessary for the performance of or entering a contract.</li>
                                                </ul>
                                            </li>
                                            <li>If certain types of Special Categories of Personal Data or Criminal Convictions Data are being processed, then grounds (b) or (c) will not be allowed. However, the Special Categories of Personal Data and Criminal Convictions Data can be processed where necessary (unless less intrusive means can be used) for substantial public interest, like fraud prevention.</li>
                                            <li>If a decision is to be based solely on Automated Processing (including profiling), then the Data Subject must be informed when you first communicate with them of their right to object. This right must be explicitly brought to their attention and presented clearly and separately from other information. Further, suitable measures must be implemented to safeguard the Data Subject's rights, freedoms, and legitimate interests.</li>
                                            <li>We must also inform the Data Subject of the logic involved in the decision-making or profiling, the significance, and the envisaged consequences, and give the Data Subject the right to request human intervention, express their point of view, or challenge the decision.</li>
                                            <li>A DPIA must be carried out before any Automated Processing (including profiling) or ADM activities are undertaken.</li>
                                            <li>Where you are involved in any data Processing activity that involves profiling or ADM, you must consult with your DPO.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Direct marketing</span>
                                        <ol className='pContanier'>
                                            <li>We are subject to certain rules and privacy laws when engaging in direct marketing to our customers and prospective customers (for example, when sending marketing emails or making telephone sales calls).</li>
                                            <li>For example, in a business-to-consumer context, a Data Subject's prior consent is generally required for direct electronic marketing (for example, by email, text, or automated calls). The limited exception for existing customers, known as "soft opt-in", allows an organization to send marketing texts or emails without consent if it:
                                                <ul>
                                                    <li>Has obtained contact details during a sale to that person.</li>
                                                    <li>Is marketing similar products or services?</li>
                                                    <li>Gave the person an opportunity to opt out of marketing when first collecting the details and in every subsequent marketing message.</li>
                                                </ul>
                                            </li>
                                            <li>The right to object to direct marketing must be explicitly offered to the Data Subject in an intelligible manner so that it is clearly distinguishable from other information.</li>
                                            <li>A Data Subject's objection to direct marketing must always be promptly honored. If customers or patients opt out of marketing at any time, their details should be suppressed as soon as possible. Suppression involves retaining just enough information to ensure that marketing preferences are respected in the future.</li>
                                            <li>You must comply with the Company's guidelines on direct marketing to customers and patients, and you should consult your DPO if you are unsure of how to comply with either the Company's guidelines or the law.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Sharing Personal Data</span>
                                        <ol className='pContanier'>
                                            <li>Generally, we are not allowed to share Personal Data with third parties unless certain safeguards and contractual arrangements have been put in place.</li>
                                            <li>You must comply with the Company's data-sharing guidelines with third parties.</li>
                                            <li>If the Practice is carrying out NHS dentistry, then please note the Guidelines at NHS England</li>
                                            <li>Then the Company shall review the Data Protection requirements (and compatibility with this Policy) set out at www.digital.nhs.uk, which will change from time to time and is under our control, and a risk assessment shall be carried out regularly by the Company by the DPO at the following web site (subject to change) https://www.dsptoolkit.nhs.uk. The Company shall conduct an online self-assessment against the National Data Guardian’s 10 data security standards. Any failures in reaching those standards shall be immediately actioned by the Company and DPO</li>
                                            <li>You may only share the Personal Data we hold with another employee, agent or representative of our group (which includes our subsidiaries and our ultimate holding company along with its subsidiaries) if the recipient has a job-related need to know the information and the transfer complies with any applicable cross-border transfer restrictions.</li>
                                            <li>You may only share the Personal Data we hold with third parties, such as our service providers, if:
                                                <ul>
                                                    <li>they have a need to know the information for the purposes of providing the contracted services.</li>
                                                    <li>sharing the Personal Data complies with the Privacy Notice provided to the Data Subject and, if required, the Data Subject's Consent has been obtained.</li>
                                                    <li>the third party has agreed to comply with the required data security standards, policies and procedures and put adequate security measures in place.</li>
                                                    <li>the transfer complies with any applicable cross-border transfer restrictions and</li>
                                                    <li>a fully executed written contract that contains UK GDPR-approved third-party clauses has been obtained.</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Caldicott Principles</span>
                                        <ol className='pContanier'>
                                            <li>We and You follow the eight Caldicott principles for handling patient-identifiable information.
                                                <ul>
                                                    <li>Justify the purpose(s) of every proposed use or transfer</li>
                                                    <li>Don't use it unless it is necessary, and</li>
                                                    <li>Use the minimum necessary</li>
                                                    <li>Access to it is on a strict need-to-know basis</li>
                                                    <li>Everyone with access to it is aware of their responsibilities and</li>
                                                    <li>Understand and comply with the law</li>
                                                    <li>The Duty to share information can be as important as the duty to protect patient confidentiality</li>
                                                    <li>Inform patients and service users about how their confidential information is used</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Changes to this Data Protection Policy</span>
                                        <ol className='pContanier'>
                                            <li>We keep this Data Protection Policy under regular review.</li>
                                            <li>The latest approved version of this document supersedes all other versions, upon receipt of the latest approved version all other versions should be destroyed, unless specifically stated that previous version(s) are to remain extant. If in any doubt, please contact the document Author.</li>
                                            <li>Approved By: Dr. Jasmin Thoria, Dr. Kuldipsinh Gohil, Sukhdeep Kaeda Date Published: 28/06/2024</li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-three">
                            <div className='policy'>
                                <h1 className='policyh1 mt-0'>Social Media Policy</h1>
                                <h2>Contents</h2>
                                <ol className='listol'>
                                    <li>About this policy</li>
                                    <li>Who does this policy apply to?</li>
                                    <li>Who is responsible for this policy</li>
                                    <li>Compliance with related policies and agreements</li>
                                    <li>Personal use of social media</li>
                                    <li>Prohibited use</li>
                                    <li>Business use of social media</li>
                                    <li>Guidelines for responsible use of social media</li>
                                    <li>Monitoring</li>
                                    <li>Recruitment</li>
                                    <li>Breach of this policy</li>
                                </ol>
                                <ol className='ollicountdot'>
                                    <li className='h2li'>
                                        <span>About this policy</span>
                                        <ol className='pContanier'>
                                            <li>This policy aims to minimize the risks to our business through social media. This policy applies to the use of all forms of social media, including all social networking sites, internet postings, and blogs. It applies to using social media for business and personal purposes that may affect our business in any way.</li>
                                            <li>This policy does not form part of any employment or other contract to provide services, and we may amend it at any time.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>To Whom does this policy apply?</span>
                                        <ol className='pContanier'>
                                            <li>This policy applies to all employees, self-employed team members, contractors, casual workers, agency workers, volunteers, and interns.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Who is responsible for this policy?</span>
                                        <ol className='pContanier'>
                                            <li>The Company has delegated responsibility for overseeing its implementation to Sukhdeep Kaeda (PM). Questions about the content of this policy or suggestions for change should be reported to the PM.</li>
                                            <li>You should ask the PM any questions you may have about the day-to-day application of this policy (including reporting the misuse of social media).</li>
                                            <li>This policy is reviewed annually by the PM.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Compliance with related policies and agreements</span>
                                        <ol className='pContanier'>
                                            <li>You should never use social media in a way that breaches any of our other policies. If an internet post breaches our policies in another forum, it would also breach them online. For example, you are prohibited from using social media to:
                                                <ul>
                                                    <li>Breach our IT and Communications Systems Policy;</li>
                                                    <li>Breach any obligations we may have with respect to the rules of relevant regulatory bodies;</li>
                                                    <li>Breach any obligations contained in those policies relating to confidentiality;</li>
                                                    <li>Breach our disciplinary procedures;</li>
                                                    <li>Harass or bully other staff in any way OR breach our bullying and harassment policy within the Employee Handbook.</li>
                                                    <li>Unlawfully discriminate against other staff or third parties OR breach our Equality, Diversity & Human Rights policy within the Employee Handbook;</li>
                                                    <li>Breach our Data Protection Policy (for example, you should never disclose personal information about a colleague online), or</li>
                                                    <li>Breach any other laws or regulatory requirements.</li>
                                                </ul>
                                            </li>
                                            <li>You should never provide references for other individuals on social or professional networking sites. These positive and negative references can be attributed to the organization and create legal liability for both the author of the reference and the organization.</li>
                                            <li>If you breach any of the above policies, you will be subject to disciplinary action up to and including termination of employment.</li>
                                        </ol>
                                    </li>

                                    <li className='h2li'>
                                        <span>Personal use of social media</span>
                                        <ol className='pContanier'>
                                            <li>Personal use of social media is never permitted during working hours or by means of our computers, networks, and other IT resources and communications systems.</li>
                                        </ol>
                                    </li>

                                    <li className='h2li'>
                                        <span>Prohibited use</span>
                                        <ol className='pContanier'>
                                            <li>You must avoid making social media communications that could indirectly damage our business interests or reputation.</li>
                                            <li>You must not use social media to:
                                                <ul>
                                                    <li>Defame or disparage us, our staff, or any third party;</li>
                                                    <li>Harass, bully, or unlawfully discriminate against staff or third parties;</li>
                                                    <li>Make false or misleading statements, or</li>
                                                    <li>Impersonate colleagues or third parties.</li>
                                                </ul>
                                            </li>
                                            <li>You must not express opinions on our behalf via social media unless expressly authorized to do so by your manager. You may be required to undergo training to obtain this authorization.</li>
                                            <li>You must not post comments about sensitive business-related topics, such as our performance, or do anything to jeopardize our trade secrets, confidential information, and intellectual property. You must not include our logos or other trademarks in any social media posting or your profile on any social media.</li>
                                            <li>You are not permitted to add students of the college you meet during class to personal social networking accounts.</li>
                                        </ol>
                                    </li>

                                    <li className='h2li'>
                                        <span>Business use of social media</span>
                                        <ol className='pContanier'>
                                            <li>If your duties require you to speak on behalf of the organization in a social media environment, you must still seek approval for that communication from the PM.</li>
                                            <li>Likewise, if you are contacted for comments about the organization for publication anywhere, including in any social media outlet, direct the inquiry to the PM and do not respond without written approval.</li>
                                            <li>The use of social media for business purposes is subject to the remainder of this policy.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Guidelines for responsible use of social media</span>
                                        <ol className='pContanier'>
                                            <li>You should clearly state in social media postings or your personal profile that you are speaking on your behalf. Write in the first person and use a personal email address.</li>
                                            <li>Be respectful to others when making any statement on social media and be aware that you are personally responsible for all communications published online for anyone to see.</li>
                                            <li>If you disclose your affiliation with us on your profile or in any social media postings, you must state that your views do not represent those of your employer (unless you are authorized to speak on our behalf as set out in paragraph 6.3). You should also ensure that your profile and any content you post are consistent with the professional image you present to clients and colleagues.</li>
                                            <li>If you are uncertain or concerned about the appropriateness of any statement or posting, refrain from posting until you have discussed it with your line manager.</li>
                                            <li>If you see social media content that disparages or reflects poorly on us, you should contact the PM</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Monitoring</span>
                                        <ol className='pContanier'>
                                            <li>We reserve the right to monitor, intercept, and review, without further notice, your activities using our IT resources and communications systems, including but not limited to social media postings and activities, for legitimate business purposes, which include:
                                                <ul>
                                                    <li>Ascertaining and demonstrating that in using the systems, you are meeting expected standards and</li>
                                                    <li>The detection and investigation of unauthorized use of the systems (including where this is necessary to prevent or detect crime).</li>
                                                </ul>
                                            </li>
                                            <li>For further information, see our IT and Communications Systems Policy on our compliance portal.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Recruitment</span>
                                        <ol className='pContanier'>
                                            <li>We may use internet searches to perform due diligence on candidates during recruitment. Where we do this, we will act in accordance with our data protection and equal opportunities obligations.</li>
                                        </ol>
                                    </li>
                                    <li className='h2li'>
                                        <span>Breach of this policy</span>
                                        <ol className='pContanier'>
                                            <li>Breach of this policy may result in disciplinary action up to and including dismissal. If we suspect you have committed a breach of this policy, you are required to cooperate with our investigation.</li>
                                            <li>You may be required to remove any social media content that we consider a breach of this policy. Failure to comply with that request may result in disciplinary action.</li>
                                        </ol>
                                    </li>
                                </ol>
                                <p>The latest approved version of this document supersedes all other versions, upon receipt of the latest approved version all other versions should be destroyed, unless specifically stated that previous version(s) are to remain extant. If in any doubt, please contact the document Author.</p>
                                <p>Approved By: Dr Jasmin Thoria, Dr Kuldipsinh Gohil, Sukhdeep Kaeda Date Published: 28/06/2024</p>
                            </div>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-four">
                            <div className='policy'>
                                <h1 className='policyh1 mt-0'>Refund Policy</h1>
                                <h1>Our privacy policy</h1>
                                <ul>
                                    <li>You can cancel for a full refund within 14 days of booking the course.</li>
                                    <li>After 14 days and up to 25 days before the start of the course, you can cancel
                                        and are entitled to a refund of 75%.</li>
                                    <li>14 days or less to the start of the course you can cancel but you are not
                                        entitled to a refund.</li>
                                    <li>If you book a course within 25 days of the start date, you are not entitled to a
                                        refund.</li>
                                </ul>
                                <h1>Please note:</h1>
                                <ul>
                                    <li>Occasionally, due to unforeseen circumstances, a course may have to be
                                        canceled. In this situation, we will endeavor to give as much notice as
                                        possible and you will receive a full refund for the course cost. We cannot,
                                        however, refund other incurred expenses, for example, pre-purchased travel
                                        or accommodation.</li>
                                </ul>
                                <h1>Course certificates</h1>
                                <ul>
                                    <li>At the end of your course, you will be issued with a CPD certificate. If you
                                        need this certificate reissued in the future, there will be a £20 administration
                                        fee payable.</li>
                                </ul>
                            </div>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-five">
                            <div className='policy'>
                                <h1 className='policyh1 mt-0'>Equality and Diversity Policy</h1>
                                <h1>Purpose</h1>
                                <ul>
                                    <li>Prevent, reduce, and stop all forms of unlawful discrimination within Sage Academy  and its services.</li>
                                    <li>Promote equality and diversity.</li>
                                </ul>
                                <p>
                                    This policy aims to ensure that all aspects of Sage Academy’s work comply with the Equality Act 2010, Northern Ireland Equality Law, and any other UK Equalities legislation as may be updated from time to time. The policy seeks to remove unfair and discriminatory practices within the organization, its qualifications, services, and to encourage full contribution from its diverse community. Sage Academy believes that all employees, trustees, associates, suppliers, and users of our services are entitled to be treated with respect and dignity and free from discrimination and abuse. This policy is aimed at our centers and learners who are delivering/enrolled on or have taken a Sage Academy  approved qualification or unit.
                                </p>
                                <h1>Scope</h1>
                                <ul>
                                    <li>Everyone who works for or acts on behalf of Sage Academy - including staff, trustees, Committee Members, and associates.</li>
                                    <li>The development, delivery, and evaluation of Sage Academy’s qualifications – ensuring that groups of learners who share a particular characteristic are not disadvantaged by the assessments other than by being directly related to the required competence standards.</li>
                                    <li>The requirements that Sage Academy places on its Centers via the Centre Agreement.</li>
                                </ul>
                                <p>This policy does not form a part of any employment contract with any employee and its contents are not to be regarded by any person as implied, collateral, or express terms to any contract made with Sage Academy.</p>
                                <h1>Centre Responsibility</h1>
                                <p>It’s important that your staff involved in the delivery of our qualifications and your learners are fully aware of the contents of the policy (e.g., via their induction when first embarking on Sage Academy qualifications).</p>
                                <p>Sage Academy  reserves the right to amend and update this policy at any time.</p>
                                <p>We’ll review the policy annually and revise it as and when necessary, in response to customer and learner feedback, changes in our practices, actions from the regulatory authorities or external agencies, or changes in legislation.</p>
                                <h1>Policy Statement</h1>
                                <p>Sage Academy is committed to actively opposing all forms of discrimination and promoting equality and diversity.</p>
                                <p>Sage Academy  aims to achieve a working and assessment environment that provides equality of opportunity and freedom from unlawful discrimination on the grounds of: race, sex, pregnancy, and maternity, marital or civil partnership status, gender reassignment, disability, religion or beliefs, age, or sexual orientation.</p>
                                <p>Specifically, our policy is that in relation to:</p>
                                <p><b>1. Our People (staff, trustees, and associates):</b></p>
                                <ul>
                                    <li>Promoting equality of opportunity and anti-discriminatory practice. Sage Academy is committed to incorporating specific and appropriate duties in respect of implementing the equal opportunities policy into job descriptions and work objectives of all staff.</li>
                                    <li>To provide equality training and guidance at induction and throughout their time working forSage Academy. Sage Academy will provide equality training and guidance as appropriate to our staff; including as part of staff’s induction training as well as further on-going courses as identified via our internal staff performance review arrangements.</li>
                                    <li>To ensure that staff performance and actions comply with all Equality and Diversity Laws.</li>
                                    <li>To have the ability to collect information on diversity, requests for reasonable adjustments, and incidents of concern, only where appropriate and with explicit consent, in relation to this policy so that we can take action to address any shortfalls.</li>
                                </ul>
                                <p><b>2. Our Qualifications and Assessments:</b></p>
                                <ul>
                                    <li>Will ensure that there are no features that could disadvantage any groups of learners that share a particular characteristic or barriers to entry other than those directly related to the purpose of the units or qualifications and the competence standards required.</li>
                                    <li>Will state the nature of any such features or barriers and justify the inclusion of any such requirements that create the barrier in terms of why they are required for the particular unit or qualification.</li>
                                    <li>May, as part of learner registration and certification processes for qualifications and units, collect requests for special considerations, reasonable adjustments, and feedback from learners and centers.</li>
                                </ul>
                                <p><b>3. Our Centers:</b></p>
                                <ul>
                                    <li>Expected to enable learners to have equal access to training and assessment for qualifications irrespective of their sex, marital status, age, religion, color, race, nationality or ethnic origin, or disability.</li>
                                    <li>Expected to deliver training and assessment without discrimination either directly, or indirectly or as a result of pressure from other bodies.</li>
                                    <li>Required to have in place a policy to promote equality and diversity and will make learners aware of their policy on Equality & Diversity, potentially as part of learner induction onto a Sage Academy course.</li>
                                    <li>Monitored and quality assured that such arrangements are in place, are applied and are effective.</li>
                                    <li>Will consider issues of inequality and discrimination made by a learner about a center when they have not been able to satisfactorily resolve the issue themselves. Learners can use Sage Academy’s Appeals Policy which sets out the arrangements outlined for doing this.</li>
                                    <li>This policy should apply to all satellite/associated venues and there should be arrangements in place to monitor its application and effectiveness.</li>
                                </ul>
                                <p>Sage Academy is committed to complying with all current and relevant legislation which, at the time of writing, includes but is not limited to the Equality Act 2010 and Northern Ireland Equality Law, and monitoring the success of arrangements made.</p>
                                <p>As part of the learner registration and certification processes for qualifications and units, Sage Academy collects information on diversity, requests for special considerations, access arrangements, and feedback from learners, centers, and other stakeholders.</p>
                                <p>All relevant issues identified that suggest that our provision or services may have unnecessarily impacted learners will be reported back to our Senior Management Team who will be responsible for ensuring that relevant staff introduce, as appropriate, amendments to provision and/or services where necessary and in accordance with our documented procedures for developing and reviewing units and qualifications.</p>
                                <h1>Definitions</h1>
                                <p>The Equality Act 2010 harmonized and replaced previous legislation (such as the Race Relations Act 1976 and the Disability Discrimination Act 1995). The Equality Act ensures consistency in making workplaces fair environments, providing education and training that is fair and complies with the law.</p>
                                <p>The Equality Act 2010 covers the same groups that were protected by previous equality legislation – age, disability, gender reassignment, race, religion or belief, sex, sexual orientation, marriage and civil partnership, and pregnancy and maternity. The Act refers to these as ‘protected characteristics’. Some protected characteristics are given extended protection under the Equality Act. The details of the protected characteristics and the different forms of discrimination are set out below for ease of reference.</p>
                                <h1>Discrimination</h1>
                                <p>Discrimination is unequal or differential treatment which leads to one person being treated more or less favorably than others are, or would be, treated in the same or similar circumstances on the grounds of race, sex, pregnancy, and maternity, marital or civil partnership status, gender reassignment, disability, religion or beliefs, age, or sexual orientation.</p>
                                <ul>
                                    <li><b>Harassment:</b> when a person is subjected to unwanted conduct that has the purpose or effect of violating theTypes of Discrimination</li>
                                    <li><b>Direct discrimination:</b> someone is treated less favorably than another because of their protected characteristic.</li>
                                    <li><b>Associative discrimination:</b> direct discrimination against someone because they associate with another person who possesses a protected characteristic.</li>
                                    <li><b>Discrimination by perception:</b> direct discrimination against someone because others think they possess a protected characteristic.</li>
                                    <li><b>Indirect discrimination:</b> can occur when certain requirements, policies, conditions, or practices that apply to everyone disadvantage those with a particular protected ir dignity or creating an intimidating, hostile, degrading, humiliating, or offensive environment. Employees can now complain of behavior they find offensive even if it is not directed at them.</li>
                                    <li><b>Harassment by a third party:</b> employers are potentially liable for harassment of their staff by people they do not employ.</li>
                                    <li><b>Bullying:</b> Bullying at work is where someone is intimidated, often in front of colleagues. In many instances, it occurs where the victim is in a less senior position but can be between peers. It is often linked to harassment where someone's behavior is offensive, for example, where sexual or racist comments are made.</li>
                                    <li><b>Victimization:</b> when a person is treated less favorably or badly because they have made or supported a complaint or grievance under the Equality Act 2010.</li>
                                </ul>
                                <h1>Unlawful Reasons for Discrimination</h1>
                                <ul>
                                    <li><b>Sex:</b> This covers sex, marital status, civil partnership, pregnancy or maternity, gender reassignment or transgender status. A person must not be treated less favorably on any of these grounds. This applies to men, women and those undergoing or intending to undergo gender reassignment. Sexual harassment of men and women can be found to constitute sex discrimination. For example, asking a woman during an interview if she is planning to have any (more) children, or what arrangements she has made for childcare, would constitute discrimination on the grounds of gender.</li>
                                    <li><b>Age:</b> A person must not be treated less favorably because of their age. This applies to people of all ages whether young or old. It does not apply when calculating redundancy payments, which are based on a statutory minimum scale.</li>
                                    <li><b>Disability:</b> A disabled person must not be treated less favorably than a non-disabled person at all stages of employment, from recruitment through to termination, or in education and training. Reasonable adjustments must be made to ensure that the disabled person has as much access to any services and ability as a non-disabled person.</li>
                                </ul>
                                <h1>Reasonable Adjustments</h1>
                                <p>Employers, and education and training providers, have a duty to make reasonable adjustments to facilitate the employment or education of a disabled person. This may include adjustments to premises, equipment, or working practices. Some examples are:</p>
                                <ul>
                                    <li>Re-allocating some or all a disabled employee’s duties.</li>
                                    <li>Transferring a disabled employee to a role better suited to their disability.</li>
                                    <li>Relocating a disabled employee to a more suitable office or workstation.</li>
                                    <li>Giving a disabled employee time off work for medical treatment or rehabilitation.</li>
                                    <li>Providing training or mentoring for a disabled employee.</li>
                                    <li>Supplying or modifying equipment, instruction, and training manuals for disabled individuals.</li>
                                </ul>
                                <p>In essence, an organization should make any other adjustments that it considers reasonable and necessary, provided such adjustments are within their financial means and feasibility. The Senior Management Team has collective responsibility for Equality & Diversity; any employee who has a disability and feels that any such adjustments could be made by the organization can contact any SMT member.</p>
                                <ul>
                                    <li><b>Race:</b> This covers race, the color of skin, nationality, or ethnic origin. A person must not be treated less favorably than another person on any of these grounds.</li>
                                    <li><b>Sexual Orientation:</b> A person must not be treated less favorably because of their sexual orientation. For example, you cannot refuse to employ a person just because he or she is homosexual, heterosexual, or bisexual.</li>
                                    <li><b>Religion or Belief:</b> A person must not be treated less favorably because of their religious beliefs or their religion or their lack of any religion or belief.</li>
                                </ul>
                                <h1>Responsibilities</h1>
                                <p>The Sage Academy Board of Trustees has overall responsibility for the effective operation of this policy and for ensuring compliance with the law relating to equality, diversity, and discrimination.</p>
                                <p>Day-to-day operational responsibility for this policy has been delegated to the Chief Executive who is responsible and accountable for:</p>
                                <ul>
                                    <li>Promoting equality and diversity within the workplace and the services which the organization offers.Incorporating specific and appropriate duties relating to equality into job and role descriptions and staff work objectives.</li>
                                    <li>Ensuring that equality and diversity training and guidance are included at induction and at regular intervals for everyone who works for Sage Academy(i.e., staff, trustees, and associates).</li>
                                    <li>Ensuring appropriate action is taken in any case where staff actions or behavior are not to the required standard.</li>
                                    <li>The collection of information about and requests for reasonable adjustments and incidents of concern relating to staff and associates, so that action can be taken to address any shortfalls.</li>
                                </ul>
                                <p>All managers and team leaders employed by Sage Academy or acting as an associate must:</p>
                                <ul>
                                    <li>Set an appropriate standard of behavior.</li>
                                    <li>Lead by example.</li>
                                    <li>Ensure that those they manage adhere to the policy and promote our aims and objectives regarding equal opportunities.</li>
                                </ul>
                                <p>All employees, trustees, associates, subcontractors, and agents of Sage Academy are required to act in a way that does not subject any other employees or service users to direct or indirect discrimination, harassment, or victimization on the grounds of their race, sex, pregnancy, or maternity, marital or civil partnership status, gender reassignment, disability, religion or beliefs, age, or sexual orientation. The cooperation of all employees, trustees, and associates is essential for the success of this policy. Employees may be held independently and individually liable for their discriminatory acts by Sage Academy  and may in certain instances be ordered by an Employment Tribunal to pay compensation to the person who has suffered as a result of discriminatory acts.</p>
                                <p>The Senior Management Team (SMT) will specifically be responsible and accountable for:</p>
                                <ul>
                                    <li>Planning, delivering, monitoring, and evaluating the delivery of assessment and awarding by Sage Academy and training delivered by Centers in a manner which promotes equality and diversity and reduces discrimination.</li>
                                    <li>Investigating data and information that suggests our provision or services may have unnecessarily impacted on learners and ensuring that relevant staff introduce, as appropriate, amendments to provision and/or services.</li>
                                    <li>Improving our documented procedures for developing and reviewing units and qualifications.</li>
                                    <li>The development of assessments and qualifications in a manner which promotes equality and diversity and reduces discrimination.</li>
                                    <li>Making evidence and findings available to qualification regulators upon request.</li>
                                </ul>
                                <p>The Education and Standards Committee (ESC) will:</p>
                                <ul>
                                    <li>Ensure that the Sage ACademy staff team and the qualification committees which they work with are aware that the way in which curriculum, syllabi, and assessment are written can be discriminatory and if necessary, organize additional training in this regard or intervene to ensure that this does not happen.</li>
                                    <li>Gather and analyze timely information on any reports, concerns, or incidents of discrimination in curriculum, syllabi, assessment, and the conduct of examinations to determine the appropriate course of action.</li>
                                    <li>Ensure that the Education Standards to which centers must adhere promote equality and diversity and reduce discrimination and that there are appropriate quality assurance and control mechanisms in place to action if there are issues.</li>
                                    <li>Regularly review the policy as part of Sage Academy self-evaluation arrangements taking into account center and learner feedback, changes in practice, actions of the regulatory authorities or other relevant external agencies, or changes in legislation.</li>
                                    <li>Make recommendations for revisions to the policy and advise the Governance Committee and the Board of these necessary revisions.</li>
                                </ul>
                                <p>The Governance Committee has the responsibility for reviewing this policy every two years and for reporting to the Board of Trustees that this has been done and recommending any changes to the policy. Recommendations for change should be reported to the Governance Committee.</p>      
                            </div>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-six">
                            <div className='policy'>
                                <h1 className='policyh1 mt-0'>Sage Academy Bullying & Harassment Policy</h1>
                                <h1>Policy Statement:</h1>
                                <p>
                                    Sage Academy is committed to fostering a respectful, inclusive, and safe workplace. Every staff member is entitled to work in an environment free from bullying, harassment, and any behavior that violates personal dignity. This policy ensures that all allegations of harassment and bullying are taken seriously and addressed promptly.
                                </p>
                                <h1>Scope and Standards of Behavior</h1>
                                <p>This policy applies to all staff, faculty, contractors, and visitors at Sage Academy. Mutual respect, individual dignity, and a commitment to non-discriminatory behavior are fundamental to our community standards. Bullying or harassment is unacceptable in any form, particularly if it creates an intimidating, hostile, or offensive environment based on protected characteristics such as age, disability, gender identity, marital status, pregnancy, race, religion, sex, or sexual orientation.</p>
                                <h1>Definitions of Bullying and Harassment</h1>

                                <p><b>1. Harassment Based on Race, Color, or Ethnic Origin:</b></p> 
                                <p>Actions targeting an individual’s race or ethnicity that are unwelcome or offensive to the recipient or a witness, including:</p>
                                <ul>
                                    <li>Racist jokes or offensive names.</li>
                                    <li>Verbal abuse based on race.</li>
                                    <li>Circulation of offensive material related to ethnicity.</li>
                                </ul>

                                <p><b>2. Sexual Harassment:</b></p> 
                                <p>Unwelcome conduct of a sexual nature directed at a colleague, including:</p>
                                <ul>
                                    <li>Unwanted physical contact or sexual advances.</li>
                                    <li>Sexually suggestive jokes or comments.</li>
                                    <li>Non-verbal gestures, staring, or intimidating behavior.</li>
                                </ul>   

                                <p><b>3. Harassment Based on Disability:</b></p> 
                                <p>Conduct targeting an individual’s disability, creating an offensive environment, such as:</p>
                                <ul>
                                    <li>Insensitive or offensive jokes about disabilities.</li>
                                    <li>Deliberate actions designed to hinder a colleague’s duties due to their disability.</li>
                                </ul>

                                <p><b>4. Harassment Based on Sexual Orientation:</b></p> 
                                <p>Conduct directed towards an individual’s sexual orientation, which is unwelcome, such as:</p>
                                <ul>
                                    <li>Jokes, offensive names, or verbal abuse based on sexual orientation.</li>
                                    <li>Deliberate actions designed to hinder a colleague’s duties due to their disability.</li>
                                </ul>

                                <p><b>5. Harassment Based on Age:</b></p> 
                                <p>Conduct targeting an individual’s age that creates an unwelcome environment, such as:</p>
                                <ul>
                                    <li>○Age-related jokes, offensive names, or insensitive stereotypes.</li>
                                </ul>

                                <h1>Bullying and Harassment Unrelated to Protected Characteristics</h1>
                                <p>Harassment can occur even if unrelated to specific protected characteristics, simply by singling out an individual for such treatment or because they associate with someone of a protected characteristic. Examples include:</p>
                                <ul>
                                    <li>Unreasonable criticism or excessive scrutiny of work.</li>
                                    <li>Social exclusion, denying leave requests unreasonably, or inappropriate humor at a colleague’s expense.</li>
                                </ul>

                                <h1>Standards of Work Behavior</h1>
                                <p>All staff are expected to:</p>
                                <ul>
                                    <li>Demonstrate courtesy and consideration toward colleagues.</li>
                                    <li>Maintain respectful and professional language in all communication.</li>
                                    <li>Avoid language or behavior that could offend or demean others.</li>
                                    <li>Respect boundaries and seek permission before adopting familiar language or behavior.</li>
                                </ul>
                                    
                                <h1>Reporting Bullying and Harassment</h1>
                                <p><b>First Step:</b></p>
                                <p>If you experience or witness inappropriate behavior, consider addressing it informally if it is isolated or appears unintentional. Politely inform the individual that their behavior is unacceptable and should not be repeated.</p>

                                <p><b>Formal Reporting:</b></p>
                                <p>For more serious concerns, deliberate actions, or repeated incidents, report the matter to Sukhdeep Kaeda, who will address the issue following Sage Academy’s grievance and disciplinary procedures. All allegations are treated with seriousness, and each case is considered individually to ensure fair and just outcomes.</p>

                               <h1>Commitment to Enforcement and Support</h1>
                               <p>Sage Academy is dedicated to ensuring that all staff work in an environment free from bullying and harassment. All incidents are addressed promptly, and disciplinary actions are taken as necessary to uphold a respectful workplace. The academy encourages openness and will support individuals who report bullying or harassment, providing a confidential process that respects the rights of all involved parties.</p>
                            </div>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-seven">
                            <div className='policy'>
                                <h1 className='policyh1 mt-0'>Health & Safety Policy</h1>
                                <p>
                                    This policy provides an overview of the College's approach to health and safety. We aim to ensure, so far as is reasonably practicable, a safe, secure, and healthy working environment for all our employees and self-employed members of the dental team as well as for patients, contractors, and other visitors who work within or visit our premises. This is achieved by ongoing evaluation and management of hazards and risks.
                                </p>
                                   

                                <ul>
        <li>A hazard is something with the potential to cause harm.</li>
        <li>A risk is the harm that can be caused by a hazard.</li>
    </ul>
    <p>All staff, whether permanent, temporary, or contracted, will be made aware of this policy document and are expected to be aware of and comply with their duties under this health and safety policy and the associated guidelines and procedures produced to support it.</p>

    <h2>Applicable Legislation and Guidelines</h2>
    <p>This policy complies with the Health and Safety at Work Act 1974 and the set of regulations commonly referred to as the "six-pack" (appended to this policy):</p>
    <ul>
        <li>The Management of Health and Safety at Work Regulations 1993 (updated 1999)</li>
        <li>The Manual Handling Operations 1992</li>
        <li>The Workplace (Health, Safety and Welfare) Regulations 1992</li>
        <li>The Health & Safety (Display Screen Equipment) Regulations 1992</li>
        <li>The Personal Protective Equipment at Work Regulations 1992</li>
        <li>The Provision and Use of Work Equipment Regulations 1992</li>
    </ul>
    <p>Additional guidelines and regulations:</p>
    <p>Health and Safety in a dental College additionally requires policies and procedures for adherence to the following regulations and guidance:</p>
    <ul>
        <li>Decontamination in Primary Care Dental Colleges - Health Technical Memorandum 01-05</li>
        <li>The Ionising Radiations Regulations 1999 (IRR9)</li>
        <li>The Ionising Radiation (Medical Exposure) Regulations 2000 (IR(ME)R2000)</li>
        <li>Health and Safety (First Aid) Regulations 1981</li>
        <li>Control of Substances Hazardous to Health Regulations 1985</li>
        <li>Electricity at Work Regulations 1989</li>
        <li>Reporting of Injuries, Diseases, and Dangerous Occurrences Regulations 1985</li>
    </ul>
    <p>This is not a comprehensive list, and there are many regulations, such as those about medicines and controlled drugs, which are too numerous to mention individually above.</p>

    <h2>Safety</h2>
    <h3>Fire Safety</h3>
    <ul>
        <li>Fire extinguishers are sited in the corridors and reception on every floor. Escape routes must be free from obstruction at all times and adequately signposted.</li>
        <li>The fire extinguisher and fire alarms are maintained and tested weekly by the College fire officer and annually by Chubb. Fire drills are conducted regularly.</li>
        <li>If a smoke detector or fire alarm sounds, members of staff should raise awareness and evacuate the building. Staff are only expected to tackle a fire if it poses no threat to their safety to do so.</li>
    </ul>

    <h3>Water Safety</h3>
    <p>Water Byelaws: The mains water supply is protected against backflow with an integrated Type A airgap anti-retraction system.</p>
    <ul>
        <li>The delivery unit is supplied by a clean water system using bottled water.</li>
        <li>Sterile water must be used during surgical procedures.</li>
        <li>Water lines must be flushed each day for 2-3 minutes.</li>
        <li>Aspirators, drains, and spittoons should be cleaned with a non-foaming disinfectant after every treatment session.</li>
        <li>Manufacturer's instructions are followed on disinfection of bottled water systems and cisterns.</li>
    </ul>
    <h4>Legionella</h4>
    <p>Legionella is more likely to proliferate in the presence of sludge, scale, rust, and algae, and when water is in the temperature range of 20-45 degrees. Water contaminated by legionellae only presents a risk when it is dispersed into the air as an aerosol. The Academy has undertaken a Legionella risk assessment with Dental Support UK and has a written scheme for the prevention of legionella contamination in water pipes and other water lines.</p>

    <h3>Aesthetic Gases</h3>
    <p>The following section applies only if aesthetic gases are used at the premises. The Academy owner ensures that the levels and concentration of aesthetic gases used in the dental surgery are kept below the Workplace Exposure Level set by the Health and Safety Executive. Aesthetic equipment is fitted with an active scavenging system, and the surgery is well-ventilated. When not in use, aesthetic gases are securely stored in a locked room. The Academy owner ensures that all aesthetic equipment and appliances are regularly checked. Records of these inspections are kept in the Academy Compliance portal.</p>

    <h3>Hazardous Substances (COSHH)</h3>
    <p>Several hazardous substances are used in the day-to-day activities of the Academy. These must be handled with care to avoid skin and eye contact, inhalation, or ingestion. The materials which pose the highest risk in dental Colleges are:</p>
    <ul>
        <li>Blood (biological agent)</li>
        <li>Saliva (biological agent)</li>
        <li>Disinfectant(s)</li>
        <li>Latex gloves</li>
        <li>Mercury, bottled</li>
        <li>Mercury, encapsulated</li>
        <li>Nitrous oxide</li>
        <li>Thick bleach (household - for general cleaning)</li>
        <li>Tray adhesive</li>
        <li>X-ray developer</li>
    </ul>
    
    <h4>COSHH Assessments</h4>
    <p>COSHH (Control of Substances Hazardous to Health) assessments of the hazardous substances used at the Academy are kept both electronically and in print format. Staff should look at these COSHH assessments and familiarise themselves with the hazards associated with each substance and the recommended means of control.</p>

    <h4>Mercury Hygiene</h4>
    <p>Mercury vaporizes at room temperature and can be absorbed into the body through inhalation or contact with the skin. The surgery must be well-ventilated to prevent the Workplace Exposure Level from being exceeded, and protective gloves worn to reduce skin contact. This Academy is equipped with amalgamators that use encapsulated amalgam, reducing the risks posed by free mercury. If free mercury is handled, any mercury spills must be cleaned up immediately. A mercury spillage kit is kept in the stock room. In the event of a mercury spill, the College manager should be informed and will decide what further action is required. Staff using Mercury may undergo biological monitoring to ensure exposure to mercury vapor is within accepted safe limits.</p>

    <h4>Cleaning Materials</h4>
    <p>Cleaning materials are kept in a locked room or cupboard, inaccessible to children.</p>

    <h4>Medicines and Medicines Storage</h4>
    <p>Medicines are stored in a locked cupboard. When a medicine is dispensed to a patient as part of his/her treatment, details of the patient, medicine (including batch number), and prescribing dentist should be entered in the medicines record (drugs) book. The same details should also be entered in the patient's records.</p>

    <h3>CPR and Emergency Drugs</h3>
    <ul>
        <li>All staff receive annual training in cardiopulmonary resuscitation from a certified training company.</li>
        <li>Emergency drugs are kept in the main surgery, as is portable oxygen and resuscitation equipment. The oxygen is maintained on a contract.</li>
        <li>Drugs are checked and updated on a rolling basis by the Academy manager.</li>
    </ul>

    <h3>Equipment Safety</h3>
    <h4>Electrical Safety (1989)</h4>
    <p>The Health and Safety Manager performs regular visual inspections of all electrical equipment, including portable appliances. Inspection and testing of portable appliances are performed annually by Shield Safety Solutions. Staff are not allowed to bring appliances to work. Electrical equipment must never be handled with wet hands, and in the event of a malfunction, disconnected from the mains supply.</p>

    <h4>Display Screen Equipment</h4>
    <p>Workstations are risk-assessed to ensure that they meet the requirements of the Display Screen Equipment Regulations 1992. Staff whose work habitually involves the use of a VDU (computer screen) for a significant part of their normal work must have regular eye and sight tests.</p>

    <h4>Radiation Safety</h4>
    <p>The College complies with the 1985 and 1988 Ionising Radiations Regulations.</p>
    <ul>
        <li>A Radiation Protection Adviser (RPA), DBG (IRS 08450066113) has been appointed for advice in complying with the requirements of IRR99. The RPA carries out a radiation safety survey every 3 years on all radiographic equipment</li>
        <li>The Radiation Protection Supervisors (RPS) are dentists at the College, Mr. Patel, Mr. D Marway,  Mr R Marway, and are responsible for ensuring that the College complies with the regulations relating to radiation protection.</li>
    </ul>

    <p>All staff are given general training about the radiation equipment used at the College. Only staff who have received appropriate training and possess the relevant knowledge may take radiographs. Such training is arranged as required. A member of staff who has not undertaken formal approved training must not use radiographic equipment at the College. Equipment must be operated following the local rules, which are displayed near each machine.
When radiographs are being taken, the operator must always stand at least 1.5 meters away from and behind the X-ray tube.
Where individual workloads exceed 150 intra-oral or 50 paranormal films a week, monitoring badges are provided by the College owner. Additional monitoring may also take place.
If radiographic equipment malfunctions, the staff member involved must immediately switch off the machine without entering the controlled zone and report the incident to the RPS.</p>





    <h3>Incident Reporting</h3>
    <p>All accidents and incidents are reported to the Health and Safety Manager. The Health and Safety Manager keeps a confidential accident report book.</p>
    <p>Where appropriate, incidents are also reported to the National Patient Safety Agency and the Care Quality Commission.</p>

    <h2>Academy Management Systems for Maintaining Health and Safety</h2>
    <p>The Academy ensures that the premises are safe and that equipment is in good working order. Staff are provided with adequate supervision, instruction, and training, as required, to maintain a safe environment and to use equipment safely. Academy health and safety arrangements include:</p>
    <ul>
        <li>Risk and Hazard Assessment</li>
        <li>Planned and Unplanned Maintenance</li>
        <li>Induction, Training & Review</li>
        <li>Regular Monitoring</li>
    </ul>

    <h3>Responsibilities and Accountabilities</h3>
    <p>The Academy Manager (Sukhdeep Kaeda) holds overall responsibility for ensuring compliance with health and safety requirements. The Health and Safety Officer (Dr. Parvin Kapoor) is responsible for ensuring that this policy is implemented and that risk assessments, accident investigations, training, and welfare programs are in place.</p>

    <h2>Pressure Vessels</h2>
    <ul>
        <li>Compressors and autoclaves are inspected annually by Independent Inspections UK Ltd, according to the written scheme of examination. They must not be used by anyone unfamiliar with their safe operation.</li>
    </ul>
    
    <h3>Autoclaves</h3>
    <ul>
        <li>All clinical staff are trained in the safe use of autoclaves.</li>
        <li>Staff who have not received training must not attempt to use any autoclave in the College. At no time should any member of staff mishandle, tamper with, or attempt to repair an autoclave.</li>
        <li>Autoclaves in the College are serviced by the manufacturer's recommendations.</li>
        <li>If an autoclave requires attention, it should be reported to the Academy manager, who will arrange for its repair.</li>
        <li>Staff are required to monitor individual autoclaves to ensure that the right conditions for sterilization are being achieved routinely.</li>
    </ul>

    <h2>Accidents</h2>
    <ul>
        <li>In case of an emergency, seek help from the qualified first-aiders/appointed persons named below.</li>
        <li>In the unlikely event of their absence, call emergency services by dialing 999.</li>
        <li>The College first-aid kit is kept in the main surgery, and a list of doctors and hospitals available to the College is kept in the office.</li>
        <li>The first aid box is maintained by the College manager, ensuring it is adequately stocked at all times.</li>
        <li>All accidents, work-related ill health, spillages, and incidents are reported immediately to the Health and Safety Manager, who will enter it in the accident report book and take necessary actions, regardless of personal injury.</li>
        <li>Accident reports are kept in a confidential file to comply with Data Protection requirements.</li>
    </ul>

    <h2>Incident Reporting</h2>
    <ul>
        <li>Safety incidents must be reported to the National Patient Safety Agency (NPSA).</li>
        <li><a href="http://www.nrls.npsa.nhs.uk/report-a-patient-safety-incident/">http://www.nrls.npsa.nhs.uk/report-a-patient-safety-incident/</a> who will share them with the Care Quality Commission.</li>
        <li>The Health and Safety Manager reports injuries, diseases, and dangerous occurrences (RIDDOR) where necessary.</li>
        <li>The manager will decide whether the accident or incident should be reported to the RIDDOR office under the Reporting of Injuries, Diseases and Dangerous Occurrences Regulations (RIDDOR) and follow up with a written report on form 2508 within 7 days, as required.</li>
    </ul>

    <h2>Academy Management Systems for Maintaining Health and Safety</h2>
    <p>The Academy will take all reasonable steps to maintain premises, equipment, and work systems so that they are safe and without risk to health for all employees and other people who may be affected by our work activities, and to provide such information, training, and supervision as needed for this purpose.</p>
    <p>This will be done through:</p>
    <ul>
        <li>Risk and Hazard Assessment</li>
        <li>Planned and Unplanned Maintenance</li>
        <li>Induction, Training & Review</li>
        <li>Regular Monitoring</li>
    </ul>
    <h2>Responsibilities and Accountabilities</h2>
    <p>Roles, responsibilities, and accountabilities for each area of health and safety are defined below. Questions about this policy or associated procedures should be raised with the registered provider or manager.</p>
    <h2>Overall Responsibility</h2>
    <p>The overall and final responsibility for health and safety issues in this Academy lies with our Academy manager Sukhdeep Kaeda, whose key responsibilities are to ensure compliance with legislation and guidelines regarding health and safety.</p>
    <h2>Implementation and Monitoring</h2>
    <p>Responsibility for ensuring that this policy is put into action belongs to the Health and Safety Officer, dr. Parvin Kapoor who has responsibility for planning and reporting:</p>
    <ul>
    <li>Risk assessments</li>
    <li>Accident and incident investigation</li>
    <li>Induction and training</li>
    <li>Workplace welfare for staff and visitors</li>
    </ul>
    <h2>Hazard Assessment and Training</h2>
    <p>The following areas where hazards could arise include:</p>
    <ul>
        <li>General: Protective clothing, material storage, first aid, correct posture, and smoking regulations (non-smokers must be protected from smoke exposure), among others.</li>
        <li>Premises: Including access, steps, flooring, lighting, ventilation, and waste disposal.</li>
        <li>Equipment: Issues such as pressure vessel explosions, electrical malfunctions, and use of computers.</li>
        <li>Laboratory: Equipment handling, dust, and fumes.</li>
    </ul>
    
    <h3>Training</h3>
    <ul>
        <li>Health and safety training, information, supervision, and instruction are provided at induction and on an ongoing basis to ensure all staff can carry out their tasks safely.</li>
        <li>Training in health and safety is ongoing to maintain compliance with safety standards.</li>
    </ul>
    
    <h3>Monitoring and Review</h3>
    <ul>
        <li>The Academy employs systems for continuous compliance monitoring, conducting risk assessments and audits, and regularly reviewing emergency plans.</li>
        <li>The Health and Safety Manager oversees safe working practices, investigates accidents, and ensures hazards are identified and addressed.</li>
    </ul>

    <h2>Team Member Responsibilities</h2>
    <ul>
        <li>Cooperate with management on health and safety matters.</li>
        <li>Avoid interference with health and safety equipment.</li>
        <li>Take reasonable care of personal health and safety.</li>
        <li>Report incidents and concerns to the Health and Safety Manager or Registered Provider.</li>
    </ul>

    <h3>Local Health & Safety Executive Contact</h3>
    <ul>
        <li>Health and Safety Team, London Borough of Ealing, Environmental Health, Perceval House, Uxbridge Road, Ealing W5 2HL.</li>
        <li>Phone: 020 8825 7468.</li>
    </ul>

    <h2>The "Six-Pack" Regulations Explained</h2>
    <h3>The Management of Health and Safety at Work Regulations (updated 1999)</h3>
    <p>These regulations require proactive management systems to be implemented by the employer.</p>
    <ul>
        <li>The Health and Safety Law poster is displayed at the Academy.</li>
        <li>Health and safety advice is available from the Health and Safety Executive.</li>
    </ul>

    <h3>Risk Assessments</h3>
    <ul>
        <li>Risks are assessed and reviewed periodically. Modifications are made if there are any significant changes in working practices or equipment.</li>
    </ul>

    <h3>Action</h3>
    <ul>
        <li>If safety procedures can be improved, appropriate steps are taken accordingly.</li>
    </ul>

    <h3>Information, Instruction, Induction, and Supervision</h3>
    <ul>
        <li>All staff are expected to familiarise themselves with hazards and risks in the Academy, under the guidance of the Health and Safety Manager. The Health and Safety Law poster is displayed, as is the employer’s liability certificate.</li>
        <li>Supervision of young workers and trainees is arranged/undertaken/monitored by the provider.</li>
    </ul>

    <h3>Organization</h3>
    <ul>
        <li>Rules and regulations are in place to ensure systematic work practices, reducing the chance of accidents.</li>
    </ul>

    <h3>Training</h3>
    <ul>
        <li>Training is conducted to avoid hazardous situations. This includes extending working hours, screen breaks, manual handling advice, and emergency contingency plans.</li>
        <li>The service provider ensures all staff receive adequate training to follow safe working practices.</li>
    </ul>

    <h3>Monitoring</h3>
    <ul>
        <li>Significant hazards are identified, and actual working practices are addressed and, if necessary, adjusted to minimize risks.</li>
        <li>The service provider checks that safe practices are followed, investigates accidents, and identifies causes of sickness-related absences. They are also responsible for acting on investigation findings to prevent recurrence.</li>
    </ul>

    <h2>Manual Handling Operations (1992)</h2>
    <ul>
        <li>Staff are trained in correct manual handling techniques, including managing smaller loads, bending from the knees, avoiding twisting, and keeping loads close to the trunk.</li>
        <li>If injury risk is high, handling is avoided, and alternative procedures are implemented to minimize risk.</li>
    </ul>
                            </div>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-eight">
                            <div className='policy'>
                            <h2>Purpose</h2>
    <p>This policy reflects Sage Academy's commitment to maintaining a fair, transparent, and consistent appeals process for learners and staff. It establishes the procedure for learners and staff to appeal decisions related to qualifications, assessments, or quality assurance at Sage Academy. This policy ensures all appeals are handled impartially and in a structured manner.</p>

    <h2>Scope</h2>
    <p>This policy applies to:</p>
    <ol>
        <li>All learners enrolled in programs or qualifications at Sage Academy who wish to appeal a decision related to assessments, grading, or quality assurance.</li>
        <li>Centers associated with Sage Academy seeking recognition or renewal, or appealing decisions about their compliance or accreditation.</li>
    </ol>

    <h2>Policy Statement</h2>
    <p>Sage Academy upholds the principle that every learner and center has the right to appeal decisions relating to qualifications, assessments, or other quality assurance measures. This policy ensures that all appeals are processed with fairness, transparency, and consistency. The procedure for making or processing an appeal is detailed in Appendix 1.</p>

    <h2>Principles for Managing Appeals:</h2>
    <ul>
        <li>Appeals can be made against decisions related to exam results, malpractice findings, audit outcomes, or procedural inconsistencies.</li>
        <li>Appeals will only be considered if it is alleged that Sage Academy did not apply its procedures consistently or failed to follow them fairly and appropriately.</li>
        <li>All appeal decisions will be handled by impartial and qualified individuals with no connection to the decision being appealed.</li>
        <li>Sage Academy will maintain confidentiality and ensure that responses are delivered within the agreed timelines.</li>
        <li>Information about the appeals process is available on the Sage Academy website.</li>
    </ul>

    <h2>Areas Covered by This Appeals Policy</h2>
    <ol>
        <li><strong>Learner Appeals</strong>
            <ul>
                <li>Appeals related to assessment decisions where Sage Academy's procedures were not applied consistently.</li>
                <li>Appeals regarding denial of reasonable adjustments or special considerations.</li>
            </ul>
        </li>
        <li><strong>Center Appeals</strong>
            <ul>
                <li>Appeals related to sanctions/actions following verification activities or malpractice investigations.</li>
                <li>Appeals about Sage Academy’s decision to approve or reject a center's application to offer a qualification.</li>
                <li>Appeals regarding quality assurance reports or curriculum adjustments.</li>
            </ul>
        </li>
    </ol>

    <h2>Responsibilities</h2>
    <ol>
        <li><strong>Sage Academy Management Committee</strong>
            <ul>
                <li>Holds overall accountability for the implementation and effectiveness of this policy.</li>
                <li>Ensures a consistent approach to appeals and reviews appeals to enhance organizational processes.</li>
            </ul>
        </li>
        <li><strong>Learners and Staff</strong>
            <ul>
                <li>Learners and staff must be aware of this policy and follow internal appeal processes before escalating to Sage Academy.</li>
                <li>Results may increase or decrease after review; learners should give written permission before their results are appealed.</li>
            </ul>
        </li>
        <li><strong>Employees and Associates</strong>
            <ul>
                <li>Employees involved in customer support or assessment roles are expected to understand and comply with this policy to protect learner interests and ensure regulatory compliance.</li>
            </ul>
        </li>
        <li><strong>Managers in Relevant Roles</strong>
            <ul>
                <li>Ensure that all employees are aware of this policy and its procedures.</li>
            </ul>
        </li>
    </ol>

    <h2>Policy Review</h2>
    <ol>
        <li><strong>Post-Appeal Review</strong>
            <ul>
                <li>Outcomes from appeals are summarized in a report for review by the designated committee, ensuring necessary improvements are identified.</li>
            </ul>
        </li>
        <li><strong>Regular Review</strong>
            <ul>
                <li>Sage Academy reviews this policy regularly, incorporating feedback from learners, staff, and regulatory guidelines to enhance the policy.</li>
            </ul>
        </li>
        <li><strong>Situations Requiring Immediate Action</strong>
            <ul>
                <li>If failures are identified (either within Sage Academy or in other institutions), the Responsible Officer will implement corrective actions and inform the regulatory authorities as necessary.</li>
            </ul>
        </li>
    </ol>

    <h2>Fees</h2>
    <p>Sage Academy may charge a fee for processing appeals to cover administrative costs. The fee will be refunded if the appeal is successful. Learners and centers are encouraged to review the appeals policy thoroughly to ensure their appeal is valid.</p>

    <h2>Definitions</h2>
    <ul>
        <li><strong>Learner:</strong> An individual enrolled in an educational course at Sage Academy.</li>
        <li><strong>Claimant:</strong> A learner or center submitting an appeal.</li>
        <li><strong>Independent Investigator:</strong> A person unconnected to Sage Academy assigned to lead formal investigations into appeals.</li>
        <li><strong>Reviewing Manager:</strong> The individual initially reviewing the appeal documentation.</li>
        <li><strong>Center:</strong> An approved organization delivering Sage Academy's programs.</li>
    </ul>

    <h2>Appendix 1: Procedure for Appeals</h2>
    <h3>Stage 1: Appeal Submission</h3>
    <p>Appeals must be submitted within 10 days of the relevant decision or report issuance. Late appeals may be considered only with valid justification and supporting evidence.</p>

    <h3>Stage 2: Initial Review</h3>
    <p>The Reviewing Manager will acknowledge receipt within 5 working days, perform an informal review, and either accept or reject the appeal based on eligibility criteria. If the Reviewing Manager has a conflict of interest, the appeal will be assigned to an alternate manager.</p>

    <h3>Stage 3: Decision Options</h3>
    <p>Sage Academy will communicate the initial decision within 10 working days:</p>
    <ul>
        <li><strong>Refusal:</strong> If the appeal does not meet eligible grounds.</li>
        <li><strong>Amendment of Decision:</strong> If new evidence warrants a revision.</li>
        <li><strong>Progress to Independent Investigation:</strong> If further review is necessary.</li>
    </ul>

    <h3>Stage 4: Independent Investigation</h3>
    <p>An independent investigator will be appointed if the appeal requires further scrutiny. This individual will review all relevant documentation and conduct additional inquiries if necessary.</p>

    <h2>Appeal Outcome Notification</h2>
    <p>The final appeal outcome will be provided within 10-15 working days for initial reviews or 20 working days for formal investigations (max 35 days).</p>

    <h2>Actions Following Appeals</h2>
    <p>If an appeal identifies a failure in Sage Academy's processes, corrective actions will be taken to prevent future occurrences and notify affected learners or centers.</p>
                            </div>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-nine">
                            <div className='policy'>
                            <h1>Introduction</h1>
    <p>This policy outlines the roles and responsibilities of the staff at Sage Academy when working with other professionals and agencies to promote the welfare of adults and children, safeguarding them from abuse and neglect. It is intended to support staff working within the Academy. Policies linked to this include:</p>
    <ul>
        <li>Safeguarding children</li>
        <li>Safeguarding adults at risk</li>
        <li>Whistleblowing</li>
        <li>Complaints</li>
        <li>Information sharing</li>
        <li>Disciplinary, equality, and diversity</li>
    </ul>

    <h2>Aims</h2>
    <p>The Sage Academy team is committed to:</p>
    <ul>
        <li>Following the guidelines set out in national safeguarding guidance, ensuring that the welfare of adults and children is paramount.</li>
        <li>Maximising people's choice, control, inclusion, and protecting their human rights.</li>
        <li>Working in partnership with other agencies to safeguard adults at risk.</li>
        <li>Ensuring safe and effective working practices are in place.</li>
        <li>Supporting staff within the organization.</li>
    </ul>

    <h2>Scope</h2>
    <p>This policy applies to all staff (permanent or temporary) of Sage Academy, as well as all individuals working on behalf of the Academy.</p>

    <h2>Academy Details</h2>
    <p>Safeguarding Lead for adults: Dr. Pravin Kapoor</p>
    <p>Local safeguarding contacts for adults: contact any local staff</p>

    <p>Safeguarding Lead for Children: Dr. Pravin Kapoor</p>
    <p>Local safeguarding contacts for children: contact any local staff</p>

    <h2>Safeguarding Adults at Risk</h2>
    <h3>Definitions</h3>
    <p>An adult at risk is defined as any person aged 18 or over who:</p>
    <ul>
        <li>Needs services due to mental, physical, or other disabilities.</li>
        <li>May be unable to protect themselves against significant harm or exploitation.</li>
    </ul>

    <h3>The Care Act 2014</h3>
    <p>The Care Act 2014 outlines a legal framework for local authorities and other systems to protect adults at risk of abuse or neglect. Safeguarding boards work across all relevant services to share information and help identify abuse. It also includes neglect and self-neglect as recognized types of abuse.</p>

    <h3>Types of Abuse</h3>
    <p>Abuse can take many forms. Staff should remain vigilant to various types of abuse, which may include:</p>
    <ul>
        <li>Physical abuse: hitting, slapping, pushing, etc.</li>
        <li>Emotional or psychological abuse: threats, humiliation, isolation, etc.</li>
        <li>Sexual abuse: unwanted sexual contact, harassment, etc.</li>
        <li>Financial abuse: misuse or theft of money, fraud, etc.</li>
        <li>Neglect: failure to provide access to appropriate services or basic necessities.</li>
        <li>Self-neglect: neglecting personal hygiene or health.</li>
        <li>Extremism: opposition to British values such as democracy or tolerance.</li>
        <li>Discriminatory abuse: harassment based on protected characteristics.</li>
        <li>Modern slavery: forced labour or human trafficking.</li>
        <li>Organisational abuse: poor practice within an institution.</li>
        <li>Domestic violence: physical, sexual, emotional, or economic abuse.</li>
    </ul>

    <h2>Responsibilities of Sage Academy</h2>
    <ul>
        <li>Take action to identify and prevent abuse from happening.</li>
        <li>Respond appropriately when abuse has occurred or is suspected.</li>
        <li>Follow safeguarding procedures at all times.</li>
        <li>Provide support, advice, and resources to staff.</li>
        <li>Ensure staff are aware of training opportunities and provide access.</li>
        <li>Maintain a safeguarding lead with expertise.</li>
        <li>Ensure diversity and beliefs are considered in safeguarding practices.</li>
    </ul>

    <h2>Responsibilities of All Staff</h2>
    <ul>
        <li>Follow safeguarding policies and procedures.</li>
        <li>Attend safeguarding training and keep knowledge current.</li>
        <li>Report concerns to the safeguarding lead.</li>
        <li>Share information and attend meetings as required.</li>
        <li>Work collaboratively with other agencies to protect at-risk individuals.</li>
    </ul>

    <h2>Reporting Abuse</h2>
    <p>If a staff member suspects abuse, they must report it to the safeguarding lead. If the safeguarding lead is implicated, follow the whistleblowing policy. If urgent medical assistance is required, staff should call for medical help immediately. If there is an immediate risk of harm, the police must be contacted.</p>

    <h2>Allegation of Abuse by a Staff Member</h2>
    <p>If a staff member is accused of abuse, the Academy’s disciplinary policy will be followed. The staff member may be suspended while the investigation is conducted. If required, the incident will be reported to relevant authorities.</p>

    <h2>Confidentiality and Information Sharing</h2>
    <p>Staff must not confuse confidentiality with secrecy. If there are concerns about abuse, they must share information with social care services, even without consent, under certain circumstances (e.g., serious crime, public interest).</p>

    <h2>Safeguarding Children</h2>
    <p>Sage Academy staff may observe signs of child abuse or hear something of concern. They must report these concerns in line with child protection policies.</p>

    <h3>Types of Abuse (Children)</h3>
    <p>Abuse can include physical harm, emotional abuse, sexual abuse, and neglect. Staff should be aware of the signs and symptoms and know how to respond.</p>

    <h3>Practical Steps if You are Worried About a Child</h3>
    <ul>
        <li>Could the injury have occurred accidentally?</li>
        <li>Does the explanation fit the injury?</li>
        <li>Observe the child’s relationship with their parent/carer.</li>
    </ul>
    <p>Staff should discuss concerns with the safeguarding lead, and if necessary, seek informal advice from social services without disclosing the child’s identity.</p>

    <h2>Your Child Protection Policy</h2>
    <p>Sage Academy is committed to protecting children from harm. This includes listening to children, providing information, and maintaining a safe environment.</p>

    <h2>Staff Training</h2>
    <p>All staff should complete safeguarding training at the appropriate level for their role. Training should be refreshed every three years.</p>

    <h2>Modern Slavery</h2>
    <p>Sage Academy has a zero-tolerance approach to slavery and human trafficking, in line with the Modern Slavery Act 2015. The Academy conducts checks to ensure staff can legally work in the UK and adheres to ethical business practices.</p>

    <h2>Female Genital Mutilation (FGM)</h2>
    <p>FGM is illegal in the UK and constitutes a violation of human rights. Staff must report any known cases to the police under the FGM Mandatory Reporting Duty, as outlined by the Serious Crime Act 2015.</p>                      
    
                            </div>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-ten">
                            <div className='policy'>
                            <h1>1. Use of Mobile Phones by Staff</h1>
    <h2>1.1 Personal Mobile Phones</h2>
    <p>Staff may need to use their personal mobile phones during academy hours under specific circumstances, such as:</p>
    <ul>
        <li>Emergency contact by their child or their child's school.</li>
        <li>Urgent situations involving acutely ill dependents or family members.</li>
        <li>Communication needs that cannot be addressed outside academy hours.</li>
    </ul>
    <p>Approval for personal mobile use in such cases should be obtained from the Principal.</p>

    <h2>1.2 Data Protection</h2>
    <p>Staff must not use personal mobile phones to handle any confidential academy information, including student or patient data. This also applies to entering such data into generative AI tools, such as ChatGPT or Google Bard. Staff should refer to the Data Protection and ICT Acceptable Use Policy for detailed guidance on data handling and security measures.</p>

    <h2>1.3 Safeguarding</h2>
    <p>Staff are required to:</p>
    <ul>
        <li>Avoid sharing personal contact details with students or parents.</li>
        <li>Avoid using personal mobile phones for photographing or recording students or their work. Official academy equipment should be used for any documentation necessary for lessons, trips, or activities.</li>
    </ul>

    <h2>1.4 Using Personal Mobiles for Work Purposes</h2>
    <p>In certain situations, it may be appropriate to use personal mobile phones for academy-related activities, such as:</p>
    <ul>
        <li>Emergency evacuations.</li>
        <li>Supervising off-site or residential visits.</li>
    </ul>
    <p>In these cases, staff must:</p>
    <ul>
        <li>Use their phones professionally, adhering to the academy’s code of conduct.</li>
        <li>Avoid taking any photos or recordings of students with personal devices.</li>
        <li>Refrain from directly contacting parents; communication should be done via the academy office or an official academy mobile if necessary.</li>
    </ul>

    <h2>1.5 Work Phones</h2>
    <p>The academy may provide work phones to specific staff members for official purposes. Only authorized staff may use these devices, and they must be used solely for academy business. Staff using academy phones should ensure all communication aligns with professional standards and the academy’s code of conduct.</p>

    <h2>1.6 Sanctions</h2>
    <p>Staff failing to adhere to this policy may face disciplinary action.</p>

    <h1>2. Use of Mobile Phones by Students</h1>
    <p>For safety and educational integrity, students’ unsanctioned use of mobile phones is prohibited on academy premises, including outdoor areas. We understand that students may bring phones for safety reasons; however, these must be switched off upon entering the academy and only turned back on after leaving the premises.</p>
    <p>Mobile phones can disrupt learning and may contribute to negative behaviors such as bullying. Urgent messages from parents should go through the academy office. In emergency cases, students may use the academy’s phone facilities to contact parents.</p>
    <p>Exceptions for mobile use may be granted during specific lessons (e.g., creative subjects) with teacher approval.</p>

    <h2>2.1 Sanctions</h2>
    <p>Students using mobile phones without permission will have their phones confiscated. The sanctions are as follows:</p>
    <ul>
        <li>First offence: Phone confiscated and returned to the student at the end of the day.</li>
        <li>Second offence: Phone confiscated and collected by a staff member.</li>
        <li>Third offence: Phone confiscated for one week.</li>
        <li>Subsequent offences: Confiscation period extended to two weeks.</li>
    </ul>
    <p>Confiscated phones will be securely stored. Phones will only be returned after the designated period, and the confiscation record resets every half term.</p>
    <p>The academy reserves the right to search a student’s phone if there is reason to believe it contains prohibited content (e.g., pornographic images or evidence of criminal conduct). A Designated Safeguarding Lead (DSL) or member of the Senior Leadership Team (SLT) must authorize any such search.</p>
    <p>The academy takes criminal activities related to mobile phone misuse seriously and may involve law enforcement in cases of:</p>
    <ul>
        <li>Sexting or sharing inappropriate images.</li>
        <li>Upskirting.</li>
        <li>Violent threats or bullying based on race, religion, or sexual orientation.</li>
    </ul>

    <p>In conclusion, Sage Academy's Mobile Phone Policy ensures a secure, professional, and focused environment for all members of the academy community. By adhering to these guidelines, staff and students contribute to a workplace culture that prioritizes confidentiality, respects boundaries, and maintains high standards of professionalism. These measures support a cohesive learning atmosphere, fostering both educational excellence and a respectful, safe environment for all.</p>
                            </div>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-eleven">
                            <div className='policy'>
                            <h1>Mission Statement</h1>
    <p>Sage Dental Academy is an independent institution governed by the College Management Committee (CMC) and led by the principal. In association with West London Dental and Sage Dental in Southall, we offer a comprehensive range of clinical and training facilities to those working and practising dentistry across London.</p>
    
    <h2>Continuing Professional Development and Flexible Learning</h2>
    <p>The Academy provides Continuing Professional Development (CPD) courses for students wishing to enhance their skills and knowledge in various areas of dentistry. For those seeking career support or professional development, the Academy offers pre-clinical training alongside focused tuition and clinical mentorship. Part-time career enhancement programmes are also available, tailored to meet the needs of 21st-century professionals. To accommodate students balancing career progression and personal commitments, the Academy provides flexible and convenient instructional formats, including practical sessions on phantom heads and advanced models.</p>
    
    <h2>Diversity and Inclusion</h2>
    <p>Sage Dental Academy is committed to promote diversity, equity, and inclusion. We ensure that students from all backgrounds have equal access to educational and career opportunities, fostering a welcoming and supportive environment for everyone.</p>
    
    <h2>Research and Innovation</h2>
    <p>In addition to offering practical training, the Academy encourages research and innovation. We support students and faculty in contributing to advancements in dental science and practice, strengthening the Academy’s reputation as a centre of academic excellence.</p>
    
    <h2>Community Engagement and Public Health</h2>
    <p>Sage Dental Academy is dedicated to engaging with the community through outreach programmes and public health initiatives. We actively promote oral health education and care for underserved populations, ensuring that the Academy’s impact extends beyond education and into the broader community.</p>
    
    <h2>Global Perspective</h2>
    <p>The Academy prepares students for the global dental landscape by fostering international collaborations and encouraging the exchange of knowledge and skills across borders. Our aim is to equip students with the competencies required for global careers in dentistry.</p>
    
    <h2>Sustainability</h2>
    <p>Sage Dental Academy is committed to incorporating sustainable practices into its operations and curricula. By fostering environmental responsibility, we ensure that future dental professionals are mindful of their environmental impact and contribute to a sustainable future.</p>
    
    <h2>Lifelong Learning and Technological Advancement</h2>
    <p>Sage Dental Academy fosters a stimulating and supportive learning environment, encouraging students to meet the high standards required for dental professionals while embracing lifelong learning. We are committed to integrating the latest technological advances to provide state-of-the-art learning and training facilities, enabling our students to thrive in an ever-evolving dental landscape.</p>
                            </div>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-twelve">
                            <div className='policy'>
                            <h1>Introduction</h1>
    <p>Welcome to SAGE! We are excited to present you with a unique approach to postgraduate study, both within the UK and internationally. We hope you find the program both professionally and academically rewarding.</p>
    <p>Postgraduate training at SAGE is a collaborative process. We highly value your feedback and encourage open discussions throughout the course. You will have opportunities to share your experience as part of this interactive learning journey.</p>
    <p>Our programs offer short courses designed to equip students with credits that can be recognized as prior learning towards modules in higher education institutions. These flexible training pathways allow General Dental Practitioners to pursue their education at a pace that suits their personal needs and circumstances.</p>
    <p>Each course has been carefully designed to provide expert tuition from leaders in various dental specialties. Alongside advanced clinical training, you will learn to develop proficiency in evidence-based dentistry. The program is intended to support your professional development by encouraging creative and independent thinking, problem-solving, sound judgment, and clear communication with both patients and colleagues.</p>
    <p>The course prepares General Dental Practitioners to meet the evolving needs of patients and offers a comprehensive approach to patient care. This includes the processes of examination, diagnosis, assessment, investigation, rehabilitation, and disease management.</p>

    <h2>Purpose of the Handbook</h2>
    <p>This handbook provides essential information regarding postgraduate courses offered by SAGE. You will find details on assessment methods, available support and guidance, and student responsibilities. The information in this handbook is accurate as of the date of publication.</p>

    <h2>Course Guide</h2>
    <p>Each course offered within a specific field has a dedicated course guide, which can be accessed on the SAGE website or in some cases as a printed copy. The guide will provide:</p>
    <ul>
        <li>The aims and intended outcomes of the course</li>
        <li>The course structure and the number of credits</li>
        <li>Contact details for teaching and administrative staff</li>
        <li>Dates for short courses for the current academic year</li>
        <li>Guidance on recommended reading and resources specific to the field</li>
    </ul>
    <h2>Support and Guidance</h2>
    <h3>Course Leader (Division Leader)</h3>
    <p>The Course Leader is responsible for the overall design, delivery, evaluation, and ongoing development of the course. They will advise you on any professional and academic matters specific to the programme, ensuring you are well supported throughout your studies.</p>

    <h3>Subject Leader</h3>
    <p>Subject Leaders are accountable for the design, delivery, evaluation, and development of particular subjects within the course. They will provide guidance on professional and academic matters, particularly regarding the assessment requirements for their subject area.</p>

    <h3>Course Coordinator</h3>
    <p>The Course Coordinator provides essential support for teaching staff and manages all the practical arrangements for the course. This includes preparing course documentation, booking rooms and equipment, managing catering, processing assessments, and liaising with guest speakers.</p>

    <h3>Student Welfare Manager</h3>
    <p>The Student Welfare Manager is here to ensure you receive the necessary guidance and support throughout your academic and professional journey. While we hope your studies progress smoothly, we understand that unforeseen circumstances may arise that affect your ability to continue your studies as planned. If you encounter any issues, the Student Welfare Manager is available to assist.</p>
    <p>The Student Welfare Manager at SAGE is Pinkesh Rajvanshi<br/>
       Email: <a href="mailto:info@sage.co.uk">info@sage.co.uk</a><br/>
       Work Telephone: 020 3468 8001</p>
    <h3>Role of the Student Welfare Manager</h3>  
    <p>Pinkesh Rajvanshi will be your first point of contact for any pastoral or non-academic matters. Their role includes:</p>
    <ul>
        <li>Offering a sympathetic, confidential listening ear.</li>
        <li>Providing advice and guidance on pastoral issues where possible, and referring students to professional services when necessary.</li>
        <li>Assisting with induction and orientation, and maintaining a supportive interest in your development throughout your studies.</li>
    </ul>
    <p>All conversations with the Student Welfare Manager are kept confidential, and any communication with third parties will require your consent.</p>

    <h3>Common Issues Addressed by the Student Welfare Manager</h3>
    <p>You may reach out to the Student Welfare Manager for assistance with the following:</p>
    <ul>
        <li>Accommodation issues</li>
        <li>Anxiety related to academic performance or progress</li>
        <li>Bereavement</li>
        <li>Course or employment changes</li>
        <li>Equal opportunities concerns</li>
        <li>Financial issues</li>
        <li>General anxiety or stress</li>
        <li>Relationship issues (partners or family)</li>
        <li>Sexual or racial harassment</li>
        <li>Support for students with disabilities</li>
        <li>Study problems and improving study skills</li>
    </ul>
    <p>Dr. Gohil or Dr. Thoria will either offer direct support or guide you to the appropriate resources for further assistance.</p>

    <h2>Other Useful Contacts</h2>
    <p>Administrator Manager: Dr. Jasmin Thoria<br/>
       Email: <a href="mailto:info@sage.uk">info@sage.uk</a><br/>
       Work telephone: +44 20 3468 8001</p>
    <p>Group Manager: Mrs Sukhdeep Kaeda<br/>
       Email: <a href="mailto:info@sage.uk">info@sage.uk</a><br/>
       Work telephone: +44 20 3468 8001</p>
    <p>Director of Quality Assurance: Dr. Jasmin Thoria<br/>
       Email: <a href="mailto:info@sage.uk">info@sage.uk</a><br/>
       Work telephone: +44 20 3468 8001</p>
    <p>Administrator: Mr. Pinkesh Rajvanshi<br/>
       Email: <a href="mailto:info@sage.co.uk">info@sage.co.uk</a><br/>
       Work telephone: +44 20 3468 8001</p>

    <h2>Attendance</h2> 
    <p>You are expected to attend all the taught sessions you have signed up for. Any optional sessions, such as study skills sessions or additional tutorials, will be clearly identified as such.</p>
    <p>While there is no strict rule specifying minimum attendance requirements, you are expected to make arrangements that enable you to attend your course without interruption. Non-attendance can affect both your progress and that of other participants, especially when the module involves practical group work or simulations.</p>
    <p>If you are unable to attend a scheduled session, it is essential to contact the Course Coordinator as soon as possible to explain your circumstances, so that appropriate arrangements can be made. If you are experiencing any difficulties in continuing your course (e.g. due to work pressure or personal matters), it is highly recommended that you seek advice from the Student Welfare Manager.</p>

    <h2>Personal & Academic Development</h2>
    <h3>Personal Development Planning (PDP)</h3>
    <p>PDP is defined as 'a structured and supported process undertaken by an individual to reflect on their learning, performance, and/or achievements, and to plan for their personal, educational, and career development' (The Higher Education).</p>
    <p>All students enrolled in credit-bearing courses within the higher education sector are expected to have PDPs. As a dentist, you are likely already maintaining a PDP as part of your career development and professional appraisal with the General Dental Council (GDC). Therefore, it is not necessary for you to prepare additional documentation. However, you may need guidance from course tutors on how to best reflect your participation in CPD courses within your professional PDP.</p>         
    <h1>Study Skills Tutorials</h1>
    <p>Course leaders and tutors are committed to preparing you for the various tasks involved in your career progression. In addition, there are optional two-hour evening tutorials focusing on study skills. These tutorials are available each term and cover topics such as:</p>
    <ul>
        <li>Preparing to write an assignment</li>
        <li>How to search for literature</li>
        <li>Reference management programs</li>
        <li>Reading and note-taking techniques</li>
        <li>Avoiding plagiarism</li>
        <li>Understanding academic levels and skills</li>
    </ul>

    <h2>Reading</h2>
    <p>In each course, you will be provided with lists of required and recommended reading related to each subject. While we expect you to engage in extensive reading for your assessments, we understand that your time is limited, and you have various other commitments. You will, of course, have access to the SAGE library, which offers a good selection of books. Additionally, online resources are increasingly important; the Academy has a wide range of subscriptions to electronic journals, which you can access from your PC at home or at work.</p>
    <p>Tutors will provide guidance to help you start on the right track, but any additional reading will be your responsibility. Developing the ability to independently identify and appraise relevant literature is a key focus of a Master’s course.</p>

    <h2>Course Assessment</h2>
    <p>The arrangements and procedures explained in this handbook govern all courses at SAGE. It is crucial that you familiarise yourself with its contents as soon as possible and seek clarification on any points that are unclear.</p>
    <p>Your course will be assessed through assignments and/or tests associated with each of the modules. In many cases, you will have a significant amount of choice regarding the specific topic and focus of your assessed work. The assignments typically provide an opportunity to apply the skills, concepts, themes, and research covered in the module to specific contexts and situations within your professional field.</p>
    <p>For each course you undertake, you will receive a guide detailing what is required for that particular module.</p>

    <h3>Special Arrangements for Assessment</h3>
    <p>If you have a disability or any other permanent condition, or if you are experiencing a temporary disability or illness that necessitates special arrangements for your course assessment (e.g., extra examination time or separate invigilation), please contact the Student Welfare Manager.</p>
    <p>If you would prefer not to take examinations on particular days during the scheduled examination period due to religious observance, please reach out to the Student Welfare Manager.</p>

    <h3>Marking and Assessment Processes</h3>
    <p>All coursework contributing to an accredited award is evaluated by two examiners. You will receive written feedback on your work, typically provided on a form that outlines the markers' assessment of your level of achievement against each category of the generic assessment criteria. The feedback will include discursive comments highlighting the strengths of your work and suggesting areas for improvement.</p>

    <h3>Marking Descriptors</h3>
    <h4>1. Demonstration of Knowledge and Understanding</h4>
    <p>The marker will assess the following:</p>
    <ul>
        <li>Demonstration of depth and systematic understanding in the topic and across areas</li>
        <li>Main topics are identified and the key issues are addressed.</li>
        <li>Demonstration that the students can work with theoretical/research-based knowledge at the forefront of their academic discipline.</li>
        <li>Comprehensive understanding of techniques and methodologies applicable to the topic</li>
        <li>High quality of contemporary literature was accessed</li>
    </ul>
    <table border="1">
        <thead>
            <tr>
                <th>Percentage</th>
                <th>Descriptor</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Above 80%</td>
                <td>Exceptional level of understanding and knowledge demonstrated by a piece of work of a level of peer-reviewed publications</td>
            </tr>
            <tr>
                <td>70-79%</td>
                <td>Excellent level of understanding and knowledge that reflects reading from a relevant and extensive list of publications.</td>
            </tr>
            <tr>
                <td>60-69%</td>
                <td>Very good level of understanding and knowledge of the key topics, which reflects a good level of background reading or appropriate literature.</td>
            </tr>
            <tr>
                <td>50-59%</td>
                <td>Understanding of the main issues is demonstrated, which reflects a reasonable level of background reading.</td>
            </tr>
            <tr>
                <td>40-49%</td>
                <td>The level of knowledge does not demonstrate Level 7 study and the accessed literature is not of the required quality.</td>
            </tr>
            <tr>
                <td>30-39%</td>
                <td>Very poor level of understanding and knowledge and evidence of poor or no background reading</td>
            </tr>
        </tbody>
    </table>

    <h4>2. Cognitive, Analytical, and Evaluation Skills</h4>
    <p>When assessing your assignments, the marker will evaluate the following key areas:</p>
    <ul>
        <li>Analysis: The extent to which you demonstrate critical awareness and the ability to analyze complex or contradictory areas of knowledge. This includes how effectively you communicate these insights within your assignment.</li>
        <li>Synthesis: Your ability to synthesize information in a potentially innovative way, drawing on knowledge or processes at the forefront of your discipline or practice.</li>
        <li>Evaluation: Your level of conceptual understanding, demonstrated by your capacity to critically evaluate research, methodologies, and present alternative approaches in a reasoned and coherent manner.</li>
        <li>Application: The demonstration of initiative and originality in problem-solving, showing how well you apply theoretical knowledge to practical scenarios</li>
    </ul>
    <table>
        <thead>
            <tr>
                <th>Percentage</th>
                <th>Descriptor</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Above 80%</td>
                <td>Exceptional level of critical analysis and reflection and demonstration that concepts are evaluated to a superior level in a critical way with strengths and weaknesses of the evidence presented.</td>
            </tr>
            <tr>
                <td>70-79%</td>
                <td>Excellent level of critical analysis and reflection and demonstration that concepts are evaluated to a superior level in a critical way with strengths and weaknesses of the evidence presented.</td>
            </tr>
            <tr>
                <td>60-69%</td>
                <td>Very good level of critical analysis and reflection and demonstration that concepts are evaluated to a superior level in a critical way with strengths and weaknesses of the evidence presented.</td>
            </tr>
            <tr>
                <td>50-59%</td>
                <td>Demonstration of some critical analysis is evident; however it is limited and little evaluation of the concepts is presented.</td>
            </tr>
            <tr>
                <td>40-49%</td>
                <td>The writing is descriptive and there is no demonstration of critical analysis or evaluation.</td>
            </tr>
            <tr>
                <td>30-39%</td>
                <td>Very poor or no analysis and evaluation of the literature.</td>
            </tr>
        </tbody>
    </table>
    <h4>3. Application to Clinical Practice</h4>
    <p>The marker will assess the following:</p>
    <ul>
        <li>Credible Links between Theory and Clinical Practice: The marker will evaluate how effectively you establish connections between theoretical knowledge and practical clinical applications. This includes making evidence-based recommendations for improving current practices.</li>
        <li>Critical Analysis of Evidence: You will be assessed on your ability to critically analyze available evidence and demonstrate how it can influence and enhance clinical practice. The depth of your analysis and how convincingly you integrate research to support changes in practice will be key.</li>
    </ul>
    <table>
        <thead>
            <tr>
                <th>Percentage</th>
                <th>Descriptor</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Above 80%</td>
                <td>Exceptional level of credible link between theory and clinical practice with excellent presentation of clinically orientated conclusion.</td>
            </tr>
            <tr>
                <td>70-79%</td>
                <td>Excellent level of credible link between theory and clinical practice with excellent presentation of clinically orientated conclusion.</td>
            </tr>
            <tr>
                <td>60-69%</td>
                <td>Very good level of credible link between theory and clinical practice with evident presentation of clinically orientated conclusion.</td>
            </tr>
            <tr>
                <td>50-59%</td>
                <td>Demonstration of link between theory and clinical practice is evident; however it is limited.</td>
            </tr>
            <tr>
                <td>40-49%</td>
                <td>There is poor link between theory and clinical practice.</td>
            </tr>
            <tr>
                <td>30-39%</td>
                <td>There is no reference to any link between theory and clinical practice in the assignment.</td>
            </tr>
        </tbody>
    </table>
    <h4>4. Presentation</h4>
    <p>The marker will assess the following:</p>
    <ul>
        <li>Structure of the Assignment: The overall organization of your assignment will be evaluated for logical flow and clarity.</li>
        <li>Coherent and Appropriate Referencing: Correct use of the Harvard referencing system is essential. You will be assessed on your ability to cite and reference sources accurately and consistently.</li>
        <li>Writing Style: The writing should be clear, concise, and professional. Your use of technical language should be precise and appropriate for the subject.</li>
        <li>Spelling and Grammar: Correct spelling, grammar, and punctuation are crucial.</li>
        <li>Appropriate Length: The assignment must adhere to the specified word limit, ensuring it is neither too short nor excessively long.</li>
    </ul>
    <table>
        <thead>
            <tr>
                <th>Percentage</th>
                <th>Descriptor</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Above 80%</td>
                <td>Work is creatively structured and extremely clear and presented to exceptional level of clarity, appropriate structure, correct spelling and grammar, correct citation of references and referencing technique.</td>
            </tr>
            <tr>
                <td>70-79%</td>
                <td>Excellent level of writing with clarity, appropriate structure, correct spelling and grammar, correct citation of references and referencing technique.</td>
            </tr>
            <tr>
                <td>60-69%</td>
                <td>Very good level of writing with clarity, appropriate structure, correct spelling and grammar, correct citation of references and referencing technique.</td>
            </tr>
            <tr>
                <td>50-59%</td>
                <td>Satisfactory level of writing, however it lacks some clarity. Satisfactory structure of the assignment. The work has some spelling and grammar errors. Acceptable referencing technique.</td>
            </tr>
            <tr>
                <td>40-49%</td>
                <td>Poor writing, which lacks clarity and structure. The work has significant spelling and grammar errors. Unacceptable referencing technique.</td>
            </tr>
            <tr>
                <td>30-39%</td>
                <td>Very poor writing, with no clarity and structure. The work has many spelling and grammar errors. The work is not referenced.</td>
            </tr>
        </tbody>
    </table>
    <h2>Appeals</h2>
    <p>Under certain defined circumstances, students may appeal against decisions relating to their academic progress or outcomes. For postgraduate students, these circumstances may be summarized broadly as follows:</p>

    <h3>Postgraduate Taught Students</h3>
    <p>Postgraduate taught students have the right to appeal if it is decided that their performance merits the award of a lower qualification than the one for which they were registered, or does not merit the award of a qualification at all.</p>

    <p>When the Academic Council reaches one of the decisions set out below, a student has the right to appeal by writing to the Academic Council within 42 days of receiving notification:</p>
    <ul>
        <li>If no qualification is awarded and the student is required to withdraw.</li>
        <li>If a qualification other than the one for which the student is registered is awarded.</li>
        <li>If the student is required to transfer to an alternative course.</li>
    </ul>

    <h2>Extenuating Circumstances</h2>
    <p>If ill health or exceptional personal circumstances have affected your performance on the course, you can bring this to the attention of the Academic Council by contacting the Student Welfare Manager.</p>
    
    <p>The Academic Council will consider any special factors affecting the performance of individual candidates, but can only do so if these factors are known in advance of its meeting and are appropriately documented. If any special factors affected your performance, it is essential that you inform the Student Welfare Manager before the meeting of the Academic Council. If the matters are of a highly personal or sensitive nature, they do not need to be disclosed in detail to the Academic Council. The Student Welfare Manager will discuss the circumstances with you, initially in confidence if you prefer, and then support you in representing your situation.</p>

    <p>It is important to make contact as soon as you become aware that your performance has been affected. For example, if you receive a provisional mark that is a fail and you believe this is a consequence of ill health, contact the Student Welfare Manager immediately. Do not wait until the Academic Council meeting takes place.</p>

    <h2>Fitness to Practice</h2>
    <p>Postgraduate and CPD courses involve various situations where participants demonstrate their professional competence. If concerns over professional competence arise from a health professional's participation in their course of study (for example, in work presented for assessment), the institution is obliged to pay due regard to public safety.</p>

    <p>Any concerns will be communicated to the student in writing, and a response will be invited. In serious cases, the Academy Principal may recommend to the Academic Council that a report be made to the relevant professional regulatory body (such as the GDC in the case of dental practitioners).</p>

    <h2>Course Evaluation, Development, and Quality Assurance</h2>
    <p>We are committed to the continual improvement of our courses of study. Establishing and maintaining a dialogue with students is an essential part of this process. This section outlines how this is done and invites your participation in the ongoing review of our provision.</p>

    <h3>Student-Staff Liaison Committee (SSLC)</h3>
    <p>All courses operate within a system of Student-Staff Liaison Committees (SSLCs). These committees are an integral part of the Academy's quality assurance framework. SSLCs provide a forum for students and staff to discuss issues relating to a course, division, or the Academy. They are student-led committees that serve as the basis for representing students' views within the Academy.</p>

    <p>The SSLC is chaired by a student registered on one of our courses, with administrative support from Academy staff. All courses are entitled to representation on the Committee. If you are interested in participating (either as a formal course representative or as an individual), please contact your Academy Manager or the Director of Quality Assurance.</p>

    <h3>Student Evaluation Questionnaires (SEQs)</h3>
    <p>After each teaching session, you will be asked to complete a Student Evaluation Questionnaire (SEQ). Your response is anonymous, although we sometimes request background information (e.g., your professional role and stage on the course) so that we can gain a better understanding of how the course is working for different groups of participants. A full digest of SEQ responses is made and considered as part of the overall course review.</p>

    <h2>Complaints</h2>
    <p>We aim to ensure that your time with us is satisfying, challenging, and rewarding, and we encourage you to use the various informal and formal methods outlined above to help us improve and develop our courses.</p>

    <p>However, there are both informal and formal channels for making a complaint or providing feedback about a department or service at Sage. The Academy encourages informal resolution where appropriate, and also has a formal Student Academic Complaints Procedure. Full details of the various channels are available on the website.</p>

    <h2>Harassment and Bullying Policy</h2>
    <h3>Sage regards all forms of harassment as unacceptable.</h3>

    <h4>Definition of Harassment</h4>
    <p>Harassment is defined as unwanted, unsolicited, and unwelcome behavior that is offensive or intimidating to the recipient. This also applies to people who are not the direct subject of the harassment but who may be affected by it. Harassment creates conditions or an environment that a reasonable person could justifiably complain about. The behavior may relate to a person’s gender, pregnancy or maternity, disability, gender reassignment, marriage and civil partnership, race, religion, sexual orientation, age, or any other personal characteristic.</p>

    <h4>Definition of Bullying</h4>
    <p>Bullying is a form of harassment and can be defined as offensive, abusive, intimidating, malicious, or insulting behavior that causes the recipient to feel upset, threatened, humiliated, or vulnerable. Bullying can undermine an individual's self-confidence, competence, and self-esteem. There may be an overlap between harassment and bullying.</p>

    <h4>Examples of Unacceptable Behavior</h4>
    <ul>
        <li>Unnecessary or unwanted physical contact</li>
        <li>Sexually suggestive behavior, compromising sexual invitations, or demands</li>
        <li>Racial harassment, including racist jokes or graffiti</li>
        <li>Displaying offensive material</li>
        <li>Verbal or written abuse, including non-communication and deliberate exclusion from social events or day-to-day activities</li>
        <li>Derogatory name-calling and insults</li>
        <li>Threats of a physical or psychological nature</li>
        <li>Victimization due to gender, race, disability, sexual orientation, age, religion, or other beliefs</li>
        <li>Bullying behavior or language that causes fear or distress to others</li>
        <li>Abuse of power by someone in authority</li>
        <li>Incitement of others to commit harassment</li>
        <li>Electronic bullying</li>
        <li>Using social networking sites to post derogatory messages about someone</li>
        <li>Inappropriate and derogatory remarks connected to performance</li>
        <li>Use of inappropriate literature, pictures, or materials to harass others</li>
    </ul>

    <h4>Handling Complaints</h4>
    <p>If an individual makes an informal complaint of harassment or bullying, Sage has a duty of care to address it, which may include carrying out an investigation, even without the complainant's cooperation in exceptional cases. All efforts will be made to secure the complainant’s involvement.</p>

    <h4>Electronic Bullying and Social Networking</h4>
    <p>Electronic bullying refers to bullying through electronic media, such as instant messaging, emails, or text messages. Staff and students are expected to consider the content, language, and appropriateness of their communications.</p>

    <h4>What to Do If You Are Concerned</h4>
    <p>If you are concerned about harassment or bullying, you can seek advice or make a formal complaint. Sources of help are detailed in the appendices of this document.</p>

    <h4>Complaints Involving Staff and Students</h4>
    <p>Procedures for handling harassment and bullying complaints involving students are outlined in Appendix A, and complaints involving staff are detailed in Appendix B. If a complaint involves both staff and students, Sage will conduct an initial investigation.</p>

    <h4>Vexatious or Malicious Complaints</h4>
    <p>If a complaint is found to be malicious, disciplinary action may be taken against the complainant.</p>

    <h4>Monitoring and Review</h4>
    <p>The Harassment and Bullying Policy will be reviewed on an annual basis, with amendments made as necessary. The outcome of all investigations will be tracked, and any recurring issues will be addressed.</p>
                                             
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default ConfidentialityPolicy;
