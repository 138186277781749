import { useFormik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";
import * as Yup from "yup";
import { EMAIL_SUBSCRIBE } from '../constant/apiEndPoint';
import { postRequest } from '../../helper/axiosClient';
import { errorNotification, successNotification } from '../../helper/notification';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

const Footer = () => {

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address.")
      .required("Email is required."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      subscribeEmail(values, resetForm);
    },
  });

  const subscribeEmail = async (values, resetForm) => {
    try {
      const payload = { ...values };
      const res = await postRequest(EMAIL_SUBSCRIBE, payload);
      if (res.type === 1) {
        successNotification(res?.response?.data?.message);
        resetForm();
      }
      if (res.type === 0) {
        errorNotification(res.errormessage);
      }
    } catch (error) {
      console.error("res====>", error);
    }
  };

  return (
    <>
      {/* Footer Start */}
      <footer>
        {/* Main Footer Start */}
        <div className="main-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                {/* About Footer Start */}
                <div className="about-footer">
                  {/* Footer Logo Start */}
                  <div className="footer-logo">
                    <figure>
                      <img src="assets/logo/Sage_Academy_Main_Long-removebg-preview.png" alt="" width={300} />
                    </figure>
                  </div>
                  {/* Footer Logo End */}
                  {/* Footer Content Start */}
                  <div className="footer-content">
                    <p>
                      As a premier Dental Academy...
                    </p>
                  </div>
                  {/* Footer Content End */}

                  {/* Footer Newsletter Start */}
                  <div className="footer-newsletter">
                    <h2 className='mb-3'>newsletter</h2>
                    <div className="subscribe-content">
                      <h3 className='my-1'>subscribe to our newsletter</h3>
                      <p>Stay informed and never miss out on the ...</p>
                    </div>
                    <div className="footer-newsletter-form">
                      <form id="newslettersForm" onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="email"
                            placeholder="Enter Your Email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                          <button type="submit" className="btn-default">
                            send{" "}
                          </button>
                        </div>
                      </form>
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                      <div
                        className="help-block with-errors"
                        style={{ color: "#F0BD6C", fontSize: "14px", }}
                      >
                        * {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                  {/* Footer Newsletter End */}
                </div>
                {/* About Footer End */}
              </div>
              <div className="col-lg-2 col-md-3 col-5">
                {/* Footer Quick Links Start */}
                <div className="footer-quick-links">
                  <h2>quick links</h2>
                  <ul>
                    <li className="">
                      <HashLink smooth to="/#home">
                        Home
                      </HashLink>
                    </li>
                    <li>
                      <HashLink smooth to="/#whoweare">
                        About us
                      </HashLink>
                    </li>
                    <li>
                      <HashLink smooth to="/privacy-policy">
                        Privacy Policies
                      </HashLink>
                    </li>
                    <li>
                      <Link to="/our-courses">
                        Course
                      </Link>
                    </li>
                    <li >
                      <HashLink smooth to="/#upcomingcourse">
                        Upcoming Course
                      </HashLink>
                    </li>
                    <li>
                      <HashLink smooth to="/#contact">
                        Contact Us
                      </HashLink>
                    </li>
                    <li>
                      <a href='https://www.the-bac.org/bac-complaints-procedure/' target='blank'>Complain to BAC</a>
                    </li>
                  </ul>
                </div>
                {/* Footer Quick Links End */}
              </div>
              <div className="col-lg-3 col-md-4 col-7">
                {/* Footer Contact Details Start */}
                <div className="footer-contact-details">
                  <h2>contact details</h2>
                  {/* Footer Contact Info Box Start */}
                  <div className="footer-contact-box">
                    {/* Info Box Start */}
                    <div className="footer-info-box">

                      <div className="icon-box">
                        <i className="fa-solid fa-location-dot" />
                      </div>
                      <p>89 South Road, Southall, Middlesex UB1 1SQ</p>
                    </div>
                    {/* Info Box End */}
                    {/* Info Box Start */}
                    <div className="footer-info-box">
                      {/* Icon Box Start */}
                      <div className="icon-box">
                        <i className="fa-solid fa-envelope-open-text" />
                      </div>
                      {/* Icon Box End */}
                      <a href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;to=info@sageacademy.uk" target='_blank'>info@sageacademy.uk</a>
                    </div>
                    {/* Info Box End */}

                    {/* Footer Social Links Start */}
                    <div className="footer-social-links mt-5">
                      <ul>
                        <li>
                          <a href="https://www.linkedin.com/company/sagedentaluk/" target='_blank'>
                            <i className="fa-brands fa-linkedin-in" />
                          </a>
                        </li>
                        {/* <li>
                        <a href="#">
                          <i className="fa-brands fa-youtube" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-twitter" />
                        </a>
                      </li> */}
                        <li>
                          <a href="https://www.instagram.com/sagedentaluk/" target='_blank'>
                            <i className="fa-brands fa-instagram" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.facebook.com/sagedentaluk" target='_blank'>
                            <i className="fa-brands fa-facebook-f" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* Footer Social Links End */}
                  </div>
                  {/* Footer Contact Info Box End */}
                </div>
                {/* Footer Contact Details End */}
              </div>
              <div className="col-lg-3 col-md-5">
                {/* Footer Newsletter Start */}
                <div className="footer-newsletter">
                  <h2>Here Find Us</h2>
                  <div id="contact" className="google-map-form contactuspopoup">
                    <div className="google-map">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.303611478852!2d-0.3803857234970298!3d51.507645671813485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487672acc6d3eeaf%3A0x3f0070a7f486be78!2sSterling%20Dental%20Centre!5e0!3m2!1sen!2sin!4v1719404715283!5m2!1sen!2sin"
                        width={600}
                        height={450}
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        className='map-frame'
                      />
                    </div>
                  </div>
                </div>
                {/* Footer Newsletter End */}
              </div>
            </div>
            {/* Footer Copyright Section Start */}
            <div className="footer-copyright">
              <div className="row">
                <div className="col-lg-12">
                  {/* Footer Copyright Start */}
                  <div className="footer-copyright-text">
                    <p>
                      copyright 2024 © <span>SAGE Academy</span> all right reserved.
                    </p>
                  </div>
                  {/* Footer Copyright End */}
                </div>
              </div>
            </div>
            {/* Footer Copyright Section End */}
          </div>
        </div>
        {/* Main Footer End */}
      </footer>
      <div style={{ position: 'relative', zIndex: 99999 }}>
        <WhatsAppWidget
          phoneNumber="12346" // Replace with your WhatsApp number
          textReplyTime="Typically replies within an hour" // Custom text
          message="Hello! How can I help you?" // Predefined message
          companyName="Sage Academy" // Your company name
          sendButton="Send" // Text for the send button
        />
      </div>
      {/* Footer End */}
    </>

  );
};

export default Footer;
